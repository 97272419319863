<template>
  <div id="iubenda_policy" class="text-black" style="padding: 72pt 72pt 72pt 72pt;">
    <div class="iub_container iub_base_container mt-4" style="background: #fafafa!important">
      <div id="wbars">
        <div class="iub_content legal_pp">
          <div class="iub_header">
            <h1 class="text-center">Guestio</h1>
            <h1 class="text-center">Privacy Policy</h1>
            <p class="text-center">Last updated November 06, 2023</p>

            <div class="mb-12">

            </div>

            <p>This Application collects some Personal Data from its Users.</p>
            <br />
            <p>
              This document contains
              <a href="#california_info" target="_self"
                >a section dedicated to California consumers and their privacy
                rights</a
              >.
            </p>
            <p>
              This document contains
              <a href="#virginia_info" target="_self"
                >a section dedicated to Virginia consumers and their privacy
                rights</a
              >.
            </p>
            <p>
              This document contains
              <a href="#colorado_info" target="_self"
                >a section dedicated to Colorado consumers and their privacy
                rights.</a
              >
            </p>
            <p>
              This document contains
              <a href="#connecticut_info" target="_self"
                >a section dedicated to Connecticut consumers and their privacy
                rights.</a
              >
            </p>
            <p>
              This document contains
              <a href="#utah_info" target="_self"
                >a section dedicated to Utah consumers and their privacy
                rights.</a
              >
            </p>
            <p>
              This document can be printed for reference by using the print
              command in the settings of any browser.
            </p>
            <br />
            <p>
              Guestio use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
            </p>
          </div>

          <!-- /header -->

          <div class="one_line_col mt-4">
            <h4 id="owner_of_the_data">
              Owner and Data Controller
            </h4>
            <p>
              Guestio, Inc. <br />250 Kyland Cove Ave <br />Las Vegas, NV 89123
            </p>
            <p><strong>Owner contact email:</strong> support@guestio.com</p>
          </div>

          <div class="one_line_col mt-4">
            <h4 id="types_of_data">
              Types of Data collected
            </h4>

            <p>
              Among the types of Personal Data that this Application collects,
              by itself or through third parties, there are: Usage Data; email
              address; Tracker; Universally unique identifier (UUID); Data
              communicated while using the service; payment data; first name;
              last name; billing address.
            </p>

            <p>
              Complete details on each type of Personal Data collected are
              provided in the dedicated sections of this privacy policy or by
              specific explanation texts displayed prior to the Data
              collection.<br />Personal Data may be freely provided by the User,
              or, in case of Usage Data, collected automatically when using this
              Application.<br />Unless specified otherwise, all Data requested
              by this Application is mandatory and failure to provide this Data
              may make it impossible for this Application to provide its
              services. In cases where this Application specifically states that
              some Data is not mandatory, Users are free not to communicate this
              Data without consequences to the availability or the functioning
              of the Service.<br />Users who are uncertain about which Personal
              Data is mandatory are welcome to contact the Owner.<br />Any use
              of Cookies – or of other tracking tools — by this Application or
              by the owners of third-party services used by this Application
              serves the purpose of providing the Service required by the User,
              in addition to any other purposes described in the present
              document and in the Cookie Policy.
            </p>
            <p>
              Users are responsible for any third-party Personal Data obtained,
              published or shared through this Application.
            </p>
          </div>

          <div class="one_line_col mt-4">
            <h4 id="place_of_processing">
              Mode and place of processing the Data
            </h4>
            <h3 class="iub-subheading iub-subheading-legal-mode-processing">
              Methods of processing
            </h3>
            <p>
              The Owner takes appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data.<br />The Data processing is carried out
              using computers and/or IT enabled tools, following organizational
              procedures and modes strictly related to the purposes indicated.
              In addition to the Owner, in some cases, the Data may be
              accessible to certain types of persons in charge, involved with
              the operation of this Application (administration, sales,
              marketing, legal, system administration) or external parties (such
              as third-party technical service providers, mail carriers, hosting
              providers, IT companies, communications agencies) appointed, if
              necessary, as Data Processors by the Owner. The updated list of
              these parties may be requested from the Owner at any time.
            </p>

            <h3 class="iub-subheading iub-subheading-legal-place">
              Place
            </h3>
            <p>
              The Data is processed at the Owner's operating offices and in any
              other places where the parties involved in the processing are
              located.<br /><br />
              Depending on the User's location, data transfers may involve
              transferring the User's Data to a country other than their own. To
              find out more about the place of processing of such transferred
              Data, Users can check the section containing details about the
              processing of Personal Data.
            </p>

            <h3 class="iub-subheading iub-subheading-legal-time">
              Retention time
            </h3>
            <p>
              Unless specified otherwise in this document, Personal Data shall
              be processed and stored for as long as required by the purpose
              they have been collected for and may be retained for longer due to
              applicable legal obligation or based on the Users’ consent.
            </p>
          </div>

          <div class="one_line_col mt-4">
            <h4 id="use_collected_data">The purposes of processing</h4>
            <p>
              The Data concerning the User is collected to allow the Owner to
              provide its Service, comply with its legal obligations, respond to
              enforcement requests, protect its rights and interests (or those
              of its Users or third parties), detect any malicious or fraudulent
              activity, as well as the following: Displaying content from
              external shows, Tag Management, User database management,
              Analytics, Handling payments, Contacting the User and Registration
              and authentication provided directly by this Application.
            </p>

            <p>
              For specific information about the Personal Data used for each
              purpose, the User may refer to the section “Detailed information
              on the processing of Personal Data”.
            </p>
          </div>

          <div data-locale="en"></div>

          <div class="one_line_col mt-4">
            <h4 id="data_processing_detailed_info">
              Detailed information on the processing of Personal Data
            </h4>
            <p>
              Personal Data is collected for the following purposes and using
              the following services:
            </p>
            <ul class="for_boxes">
              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_7988651 iub-purpose iub-purpose-7988651"
                  >
                    Analytics
                  </h3>
                  <div class="expand-content">
                    <p>
                      The services contained in this section enable the Owner to
                      monitor and analyze web traffic and can be used to keep
                      track of User behavior.
                    </p>

                    <h4 class="iub-service iub-service-6525893">
                      Google Analytics (Google LLC)
                    </h4>
                    <div class="wrap">
                      <p>
                        Google Analytics is a web analysis service provided by
                        Google LLC (“Google”). Google utilizes the Data
                        collected to track and examine the use of this
                        Application, to prepare reports on its activities and
                        share them with other Google services.<br />
                        Google may use the Data collected to contextualize and
                        personalize the ads of its own advertising network.
                      </p>
                    </div>

                    <p>Personal Data processed: Tracker; Usage Data.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >
                      –
                      <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=en"
                        onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})"
                        target="_blank"
                        >Opt Out</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>
                    </ul>

                    <h4 class="iub-service iub-service-9115765">
                      Facebook Ads conversion tracking (Facebook pixel) (Meta
                      Shows, Inc.)
                    </h4>
                    <div class="wrap">
                      <p>
                        Facebook Ads conversion tracking (Meta pixel) is an
                        analytics service provided by Meta Shows, Inc. that
                        connects data from the Meta Audience Network with
                        actions performed on this Application. The Meta pixel
                        tracks conversions that can be attributed to ads on
                        Facebook, Instagram and Meta Audience Network.
                      </p>
                    </div>

                    <p>Personal Data processed: Trackers; Usage Data.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://www.facebook.com/about/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>

                    <h4 class="iub-service iub-service-9115768">
                      Meta Events Manager (Meta Shows, Inc.)
                    </h4>
                    <div class="wrap">
                      <p>
                        Meta Events Manager is an analytics service provided by
                        Meta Shows, Inc. By integrating the Meta pixel, Meta
                        Events Manager can give the Owner insights into the
                        traffic and interactions on this Application.
                      </p>
                    </div>

                    <p>Personal Data processed: Trackers; Usage Data.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://www.facebook.com/privacy/explanation"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>

                    <h4 class="iub-service iub-service-9115771">
                      Google Analytics 4 (Google LLC)
                    </h4>
                    <div class="wrap">
                      <p>
                        Google Analytics 4 is a web analysis service provided by
                        Google LLC (“Google”). Google utilizes the Data
                        collected to track and examine the use of this
                        Application, to prepare reports on its activities and
                        share them with other Google services.<br />
                        Google may use the Data collected to contextualize and
                        personalize the ads of its own advertising network.<br />
                        In Google Analytics 4, IP addresses are used at
                        collection time and then discarded before Data is logged
                        in any data center or server. Users can learn more by
                        consulting
                        <a
                          href="https://support.google.com/analytics/answer/12017362?hl=en&amp;ref_topic=2919631"
                          target="_blank"
                          rel="noopener"
                          >Google’s official documentation</a
                        >.
                      </p>
                    </div>

                    <p>Personal Data processed: Trackers.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >
                      –
                      <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=en"
                        target="_blank"
                        rel="noopener"
                        >Opt Out</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_11626373 iub-purpose iub-purpose-11626373"
                  >
                    Contacting the User
                  </h3>
                  <div class="expand-content">
                    <h4 class="iub-service iub-service-10014218">
                      Contact form (this Application)
                    </h4>
                    <div class="wrap">
                      <p>
                        By filling in the contact form with their Data, the User
                        authorizes this Application to use these details to
                        reply to requests for information, quotes or any other
                        kind of request as indicated by the form’s header.
                      </p>
                    </div>

                    <p>Personal Data processed: first name.</p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: identifiers.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>a sharing according to the CCPA</li>
                    </ul>

                    <h4 class="iub-service iub-service-10014223">
                      Mailing list or newsletter (this Application)
                    </h4>
                    <div class="wrap">
                      <p>
                        By registering on the mailing list or for the
                        newsletter, the User’s email address will be added to
                        the contact list of those who may receive email messages
                        containing information of commercial or promotional
                        nature concerning this Application. Your email address
                        might also be added to this list as a result of signing
                        up to this Application or after making a purchase.
                      </p>
                    </div>

                    <p>Personal Data processed: first name.</p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: identifiers.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_7988635 iub-purpose iub-purpose-7988635"
                  >
                    Displaying content from external shows
                  </h3>
                  <div class="expand-content">
                    <p>
                      This type of service allows you to view content hosted on
                      external shows directly from the pages of this
                      Application and interact with them.<br />
                      This type of service might still collect web traffic data
                      for the pages where the service is installed, even when
                      Users do not use it.
                    </p>

                    <h4 class="iub-service iub-service-6525877">
                      Font Awesome (Fonticons, Inc. )
                    </h4>
                    <div class="wrap">
                      <p>
                        Font Awesome is a typeface visualization service
                        provided by Fonticons, Inc. that allows this Application
                        to incorporate content of this kind on its pages.
                      </p>
                    </div>

                    <p>Personal Data processed: Usage Data.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://fontawesome.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_7992729 iub-purpose iub-purpose-7992729"
                  >
                    Handling payments
                  </h3>
                  <div class="expand-content">
                    <p>
                      Unless otherwise specified, this Application processes any
                      payments by credit card, bank transfer or other means via
                      external payment service providers. In general and unless
                      where otherwise stated, Users are requested to provide
                      their payment details and personal information directly to
                      such payment service providers. This Application isn't
                      involved in the collection and processing of such
                      information: instead, it will only receive a notification
                      by the relevant payment service provider as to whether
                      payment has been successfully completed.
                    </p>

                    <h4 class="iub-service iub-service-6529883">Stripe</h4>
                    <div class="wrap">
                      <p>Stripe is a payment service provided by Stripe Inc.</p>
                    </div>

                    <p>
                      Personal Data processed: billing address; email address;
                      first name; last name; payment data.
                    </p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://stripe.com/gb/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >; Ireland –
                      <a
                        href="https://stripe.com/ie/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >; United Kingdom –
                      <a
                        href="https://stripe.com/ie/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: identifiers; commercial information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_11626395 iub-purpose iub-purpose-11626395"
                  >
                    Registration and authentication provided directly by this
                    Application
                  </h3>
                  <div class="expand-content">
                    <p>
                      By registering or authenticating, Users allow this
                      Application to identify them and give them access to
                      dedicated services. The Personal Data is collected and
                      stored for registration or identification purposes only.
                      The Data collected are only those necessary for the
                      provision of the service requested by the Users.
                    </p>

                    <h4 class="iub-service iub-service-10014222">
                      Direct registration (this Application)
                    </h4>
                    <div class="wrap">
                      <p>
                        The User registers by filling out the registration form
                        and providing the Personal Data directly to this
                        Application.
                      </p>
                    </div>

                    <p>Personal Data processed: first name.</p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: identifiers.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>a sharing according to the CCPA</li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_7988649 iub-purpose iub-purpose-7988649"
                  >
                    Tag Management
                  </h3>
                  <div class="expand-content">
                    <p>
                      This type of service helps the Owner to manage the tags or
                      scripts needed on this Application in a centralized
                      fashion.<br />
                      This results in the Users' Data flowing through these
                      services, potentially resulting in the retention of this
                      Data.
                    </p>

                    <h4 class="iub-service iub-service-6525891">
                      Google Tag Manager (Google LLC)
                    </h4>
                    <div class="wrap">
                      <p>
                        Google Tag Manager is a tag management service provided
                        by Google LLC.
                      </p>
                    </div>

                    <p>Personal Data processed: Usage Data.</p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: internet or other electronic network activity
                      information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li>
                <div class="box_primary box_10 expand">
                  <h3
                    class="expand-click w_icon_24 policyicon_purpose_7988650 iub-purpose iub-purpose-7988650"
                  >
                    User database management
                  </h3>
                  <div class="expand-content">
                    <p>
                      This type of service allows the Owner to build user
                      profiles by starting from an email address, a personal
                      name, or other information that the User provides to this
                      Application, as well as to track User activities through
                      analytics features. This Personal Data may also be matched
                      with publicly available information about the User (such
                      as social networks' profiles) and used to build private
                      profiles that the Owner can display and use for improving
                      this Application.<br />
                      Some of these services may also enable the sending of
                      timed messages to the User, such as emails based on
                      specific actions performed on this Application.
                    </p>

                    <h4 class="iub-service iub-service-6525892">
                      Intercom (Intercom Inc.)
                    </h4>
                    <div class="wrap">
                      <p>
                        Intercom is a User database management service provided
                        by Intercom Inc.<br />
                        Intercom can also be used as a medium for
                        communications, either through email, or through
                        messages within this Application. Intercom Messenger may
                        use Trackers to recognize and track Users behaviour.
                      </p>
                    </div>

                    <p>
                      Personal Data processed: Data communicated while using the
                      service; email address; Tracker; Universally unique
                      identifier (UUID); Usage Data; various types of Data as
                      specified in the privacy policy of the service.
                    </p>

                    <p>
                      Place of processing: United States –
                      <a
                        href="https://www.intercom.com/legal/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Privacy Policy</a
                      >.
                    </p>

                    <p>
                      Category of personal information collected according to
                      the CCPA: identifiers; internet or other electronic
                      network activity information.
                    </p>

                    <p>
                      This processing constitutes:
                    </p>

                    <ul>
                      <li>
                        a sale according to the CCPA, VCDPA, CPA, CTDPA and UCPA
                      </li>

                      <li>
                        targeted advertising according to the CPA, CTDPA and
                        UCPA
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="one_line_col">
            <h2 id="opt_out">
              Information on opting out of interest-based advertising
            </h2>
            <p>
              In addition to any opt-out feature provided by any of the services
              listed in this document, Users may learn more on how to generally
              opt out of interest-based advertising within the dedicated section
              of the Cookie Policy.
            </p>
          </div>

          <div data-locale="en"></div>

          <div class="one_line_col">
            <h2 id="cookie_policy">Cookie Policy</h2>
            <p>
              This Application uses Trackers. To learn more, Users may consult
              the
              <a
                href="https://www.iubenda.com/privacy-policy/52449323/cookie-policy"
                title="Cookie Policy"
                class="show_comp_link iframe-preserve an-preserve"
                >Cookie Policy</a
              >.
            </p>
          </div>

          <h2 id="cookie_policy_further_information">
            Further Information for Users
          </h2>

          <h3 class="iub-subheading iub-subheading-legal-basis-of-processing">
            Legal basis of processing
          </h3>
          <p>
            The Owner may process Personal Data relating to Users if one of the
            following applies:
          </p>
          <ul>
            <li>
              Users have given their consent for one or more specific purposes.
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>
          </ul>
          <p>
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>

          <h3 class="iub-subheading iub-subheading-legal-time">
            Further information about retention time
          </h3>

          <p>
            Unless specified otherwise in this document, Personal Data shall be
            processed and stored for as long as required by the purpose they
            have been collected for and may be retained for longer due to
            applicable legal obligation or based on the Users’ consent.
          </p>
          <p>Therefore:</p>
          <ul>
            <li>
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>
          </ul>
          <p>
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to
            fulfil a legal obligation or upon order of an authority.<br /><br />
            Once the retention period expires, Personal Data shall be deleted.
            Therefore, the right of access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced
            after expiration of the retention period.
          </p>

          <h3 id="rights_subjects">
            The rights of Users based on the General Data Protection Regulation
            (GDPR)
          </h3>
          <p>
            Users may exercise certain rights regarding their Data processed by
            the Owner.
          </p>

          <p>
            In particular, Users have the right to do the following, to the
            extent permitted by law:
          </p>

          <ul>
            <li>
              <strong>Withdraw their consent at any time.</strong> Users have
              the right to withdraw consent where they have previously given
              their consent to the processing of their Personal Data.
            </li>
            <li>
              <strong>Object to processing of their Data.</strong> Users have
              the right to object to the processing of their Data if the
              processing is carried out on a legal basis other than consent.
            </li>
            <li>
              <strong>Access their Data.</strong> Users have the right to learn
              if Data is being processed by the Owner, obtain disclosure
              regarding certain aspects of the processing and obtain a copy of
              the Data undergoing processing.
            </li>
            <li>
              <strong>Verify and seek rectification.</strong> Users have the
              right to verify the accuracy of their Data and ask for it to be
              updated or corrected.
            </li>
            <li>
              <strong>Restrict the processing of their Data.</strong> Users have
              the right to restrict the processing of their Data. In this case,
              the Owner will not process their Data for any purpose other than
              storing it.
            </li>
            <li>
              <strong
                >Have their Personal Data deleted or otherwise removed.</strong
              >
              Users have the right to obtain the erasure of their Data from the
              Owner.
            </li>
            <li>
              <strong
                >Receive their Data and have it transferred to another
                controller.</strong
              >
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance.
            </li>
            <li>
              <strong>Lodge a complaint.</strong> Users have the right to bring
              a claim before their competent data protection authority.
            </li>
          </ul>

          <p>
            Users are also entitled to learn about the legal basis for Data
            transfers abroad including to any international organization
            governed by public international law or set up by two or more
            countries, such as the UN, and about the security measures taken by
            the Owner to safeguard their Data.
          </p>

          <h4 class="iub-subheading iub-subheading-legal-right-to-object">
            Details about the right to object to processing
          </h4>
          <p>
            <strong
              >Where Personal Data is processed for a public interest, in the
              exercise of an official authority vested in the Owner or for the
              purposes of the legitimate interests pursued by the Owner, Users
              may object to such processing by providing a ground related to
              their particular situation to justify the objection.</strong
            >
          </p>
          <p>
            <strong
              >Users must know that, however, should their Personal Data be
              processed for direct marketing purposes, they can object to that
              processing at any time, free of charge and without providing any
              justification. Where the User objects to processing for direct
              marketing purposes, the Personal Data will no longer be processed
              for such purposes. To learn whether the Owner is processing
              Personal Data for direct marketing purposes, Users may refer to
              the relevant sections of this document.
            </strong>
          </p>

          <h4 class="iub-subheading iub-subheading-legal-how-to-exercise">
            How to exercise these rights
          </h4>
          <p>
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. Such requests
            are free of charge and will be answered by the Owner as early as
            possible and always within one month, providing Users with the
            information required by law. Any rectification or erasure of
            Personal Data or restriction of processing will be communicated by
            the Owner to each recipient, if any, to whom the Personal Data has
            been disclosed unless this proves impossible or involves
            disproportionate effort. At the Users’ request, the Owner will
            inform them about those recipients.
          </p>

          <div class="one_line_col"></div>

          <div class="one_line_col">
            <h2 id="california_info">
              Futher information for California consumers
            </h2>
            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the business running this Application and, if the case
              may be, its parent, subsidiaries and affiliates (for the purposes
              of this section referred to collectively as “we”, “us”, “our”).
            </p>
            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the state of California, United States of America, according to
              the "<a
                href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&amp;part=4.&amp;lawCode=CIV&amp;title=1.81.5"
                target="_blank"
                rel="noopener"
                >California Consumer Privacy Act of 2018</a
              >" (the "CCPA"), as updated by the "California Privacy Rights Act"
              (the "CPRA") and subsequent regulations. For such consumers, this
              section supersedes any other possibly divergent or conflicting
              information contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the terms “personal information”
              (and “sensitive personal information”) as defined in the
              California Consumer Privacy Act (CCPA).
            </p>

            <h3 id="notice_at_collection">
              Notice at collection
            </h3>

            <h4
              class="iub-subheading iub-subheading-ccpa-information-collected"
            >
              Categories of personal information collected, used, sold, or
              shared
            </h4>
            <p>
              In this section we summarize the categories of personal
              information that we've collected, used, sold, or shared and the
              purposes thereof.
              <strong
                >You can read about these activities in detail in the section
                titled “Detailed information on the processing of Personal Data”
                within this document.</strong
              >
            </p>

            <h5>
              Information we collect: the categories of personal information we
              collect
            </h5>
            <p>
              We have collected the following categories of personal information
              about you: identifiers, commercial information and internet or
              other electronic network activity information.
            </p>

            <p>
              We have collected the following categories of sensitive personal
              information: payment data
            </p>

            <p>
              We will not collect additional categories of personal information
              without notifying you.
            </p>

            <h6>
              Your right to limit the use or disclosure of your sensitive
              personal information and how you can exercise it
            </h6>
            <p>
              You have the right to request that we limit the use or disclosure
              of your sensitive personal information to only that which is
              necessary to perform the services or provide the goods, as is
              reasonably expected by an average consumer.
            </p>
            <p>
              We can also use your sensitive personal information to perform
              specific purposes set forth by the law (such as, including but not
              limited to, helping to ensure security and integrity; undertaking
              activities to verify or maintain the quality or safety of our
              service) and as authorized by the relevant regulations.
            </p>
            <p>
              Outside of the aforementioned specific purposes, you have the
              right to freely request, at any time, that we do not use or
              disclose your sensitive personal information. This means that
              whenever you ask us to stop using your sensitive personal
              information, we will abide by your request and we will instruct
              our service providers and contractors to do the same.
            </p>
            <p>
              To fully exercise your right to limit the use or disclosure of
              your sensitive personal information you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified method you can also use the privacy choices link
              provided on this Application.
            </p>
            <p>
              We use any personal information collected from you in connection
              with the submission of your request solely for the purposes of
              complying with the request.
            </p>
            <p>
              Once you have exercised this right, we are required to wait at
              least 12 months before asking whether you have changed your mind.
            </p>

            <h5>
              What are the purposes for which we use your personal information?
            </h5>
            <p>
              We may use your personal information to allow the operational
              functioning of this Application and features thereof (“business
              purposes”). In such cases, your personal information will be
              processed in a fashion necessary and proportionate to the business
              purpose for which it was collected, and strictly within the limits
              of compatible operational purposes.
            </p>
            <p>
              We may also use your personal information for other reasons such
              as for commercial purposes (as indicated within the section
              “Detailed information on the processing of Personal Data” within
              this document), as well as for complying with the law and
              defending our rights before the competent authorities where our
              rights and interests are threatened or we suffer an actual damage.
            </p>
            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.
            </p>

            <h5>How long do we keep your personal information?</h5>
            <p>
              Unless stated otherwise inside the “Detailed information on the
              processing of Personal Data” section, we will not retain your
              personal information for longer than is reasonably necessary for
              the purpose(s) they have been collected for.
            </p>

            <h5>
              How we collect information: what are the sources of the personal
              information we collect?
            </h5>
            <p>
              We collect the above-mentioned categories of personal information,
              either directly or indirectly, from you when you use this
              Application.
            </p>
            <p>
              For example, you directly provide your personal information when
              you submit requests via any forms on this Application. You also
              provide personal information indirectly when you navigate this
              Application, as personal information about you is automatically
              observed and collected.
            </p>

            <p>
              Finally, we may collect your personal information from third
              parties that work with us in connection with the Service or with
              the functioning of this Application and features thereof.
            </p>

            <h5>
              How we use the information we collect: disclosing of your personal
              information with third parties for a business purpose
            </h5>

            <p>
              For our purposes, the word “third party” means a person who is not
              any of the following: a service provider or a contractor, as
              defined by the CCPA.
            </p>
            <p>
              We disclose your personal information with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document.</b
              >
              These third parties are grouped and categorized in accordance with
              the different purposes of processing.
            </p>

            <h5>Sale or sharing of your personal information</h5>
            <p>
              For our purposes, the word “sale” means any “selling, renting,
              releasing, disclosing, disseminating, making available,
              transferring or otherwise communicating orally, in writing, or by
              electronic means, a consumer's personal information by the
              business to
              <strong
                >a third party, for monetary or other valuable
                consideration</strong
              >”, as defined by the CCPA.
            </p>
            <p>
              This means that, for example, a sale can happen whenever an
              application runs ads, or makes statistical analyses on the traffic
              or views, or simply because it uses tools such as social network
              plugins and the like.
            </p>
            <p>
              For our purposes, the word “sharing” means any “sharing, renting,
              releasing, disclosing, disseminating, making available,
              transferring, or otherwise communicating orally, in writing, or by
              electronic or other means, a consumer's personal information by
              the business to a third party for cross-context behavioral
              advertising, whether or not for monetary or other valuable
              consideration, including transactions between a business and a
              third party for cross-context behavioral advertising for the
              benefit of a business in which no money is exchanged”, as defined
              by the CCPA.<br />
              Please note that the exchange of personal information with a
              service provider pursuant to a written contract that meets the
              requirements set by the CCPA, does not constitute a sale or
              sharing of your personal information.
            </p>

            <h6>
              Your right to opt out of the sale or sharing of your personal
              information and how you can exercise it
            </h6>
            <p>
              We sell or share your personal information with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document.</b
              >
              These third parties are grouped and categorized in accordance with
              the different purposes of processing.<br /><br />You have the
              right to opt out of the sale or sharing of your personal
              information. This means that whenever you request us to stop
              selling or sharing your personal information, we will abide by
              your request.<br />Such requests can be made freely, at any time,
              without submitting any verifiable request.<br />To fully exercise
              your right to opt out, you can contact us at any time using the
              contact details provided in this document.<br />For a simplified
              opt-out method you can also use the privacy choices link provided
              on this Application.
            </p>

            <p>
              If you want to submit requests to opt out of the sale or sharing
              of personal information via a user-enabled global privacy control,
              like the Global Privacy Control (“<a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >GPC</a
              >”), you are free to do so and we will abide by such request in a
              frictionless manner (as defined in the CPRA regulations). The GPC
              consists of a setting or extension in the browser or mobile device
              and acts as a mechanism that websites can use to indicate they
              support the GPC signal. If you want to use GPC, you can download
              and enable it via a
              <a
                href="https://globalprivacycontrol.org/#download"
                target="_blank"
                rel="noopener"
                >participating browser</a
              >
              or browser extension. More information about downloading GPC is
              available
              <a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >here</a
              >.
            </p>

            <p>
              We use any personal information collected from you in connection
              with the submission of your opt-out request solely for the
              purposes of complying with the opt-out request.
            </p>
            <p>
              Once you have opted out, we are required to wait at least 12
              months before asking whether you have changed your mind.
            </p>

            <h3 class="iub-subheading iub-subheading-ccpa-your-rigths">
              Your privacy rights under the California Consumer Privacy Act and
              how to exercise them
            </h3>

            <h4>
              The right to access personal information: the right to know and to
              portability
            </h4>
            <p>You have the right to request that we disclose to you:</p>

            <ul>
              <li>
                the categories of personal information that we collect about
                you;
              </li>
              <li>
                the sources from which the personal information is collected;
              </li>
              <li>the purposes for which we use your information;</li>
              <li>to whom we disclose such information;</li>
              <li>
                the specific pieces of personal information we have collected
                about you.
              </li>
            </ul>

            <p>
              You also have
              <b
                >the right to know what personal information is sold or shared
                and to whom.</b
              >
              In particular, you have the right to request two separate lists
              from us where we disclose:
            </p>
            <ul>
              <li>
                the categories of personal information that we sold or shared
                about you and the categories of third parties to whom the
                personal information was sold or shared;
              </li>
              <li>
                the categories of personal information that we disclosed about
                you for a business purpose and the categories of persons to whom
                it was disclosed for a business purpose.
              </li>
            </ul>

            <p>
              The disclosure described above will be limited to the personal
              information collected or used over the past 12 months.
            </p>
            <p>
              If we deliver our response electronically, the information
              enclosed will be "portable", i.e. delivered in an easily usable
              format to enable you to transmit the information to another entity
              without hindrance — provided that this is technically feasible.
            </p>

            <h4>
              The right to request the deletion of your personal information
            </h4>
            <p>
              You have the right to request that we delete any of your personal
              information, subject to exceptions set forth by the law (such as,
              including but not limited to, where the information is used to
              identify and repair errors on this Application, to detect security
              incidents and protect against fraudulent or illegal activities, to
              exercise certain rights etc.).
            </p>
            <p>
              If no legal exception applies, as a result of exercising your
              right, we will delete your personal information and notify any of
              our service providers and all third parties to whom we have sold
              or shared the personal information to do so — provided that this
              is technically feasible and doesn’t involve disproportionate
              effort.
            </p>

            <h4>The right to correct inaccurate personal information</h4>
            <p>
              You have the right to request that we correct any inaccurate
              personal information we maintain about you, taking into account
              the nature of the personal information and the purposes of the
              processing of the personal information.
            </p>

            <h4>
              The right to opt out of sale or sharing of personal information
              and to limit the use of your sensitive personal information
            </h4>
            <p>
              You have the right to opt out of the sale or sharing of your
              personal information. You also have the right to request that we
              limit our use or disclosure of your sensitive personal
              information.
            </p>

            <h4>
              The right of no retaliation following opt-out or exercise of other
              rights (the right to non-discrimination)
            </h4>
            <p>
              We will not discriminate against you for exercising your rights
              under the CCPA. This means that we will not discriminate against
              you, including, but not limited to, by denying goods or services,
              charging you a different price, or providing a different level or
              quality of goods or services just because you exercised your
              consumer privacy rights.
            </p>
            <p>
              However, if you refuse to provide your personal information to us
              or ask us to delete or stop selling your personal information, and
              that personal information or sale is necessary for us to provide
              you with goods or services, we may not be able to complete that
              transaction.
            </p>
            <p>
              To the extent permitted by the law, we may offer you promotions,
              discounts, and other deals in exchange for collecting, keeping, or
              selling your personal information, provided that the financial
              incentive offered is reasonably related to the value of your
              personal information.
            </p>

            <h4>How to exercise your rights</h4>
            <p>
              To exercise the rights described above, you need to submit your
              verifiable request to us by contacting us via the details provided
              in this document.
            </p>
            <p>
              For us to respond to your request, it’s necessary that we know who
              you are. Therefore, you can only exercise the above rights by
              making a verifiable request which must:
            </p>
            <ul>
              <li>
                provide sufficient information that allows us to reasonably
                verify you are the person about whom we collected personal
                information or an authorized representative;
              </li>
              <li>
                describe your request with sufficient detail that allows us to
                properly understand, evaluate, and respond to it.
              </li>
            </ul>
            <p>
              We will not respond to any request if we are unable to verify your
              identity and therefore confirm the personal information in our
              possession actually relates to you.
            </p>

            <p>
              Making a verifiable consumer request does not require you to
              create an account with us. We will use any personal information
              collected from you in connection with the verification of your
              request solely for the purposes of verification and shall not
              further disclose the personal information, retain it longer than
              necessary for purposes of verification, or use it for unrelated
              purposes.
            </p>
            <p>
              If you cannot personally submit a verifiable request, you can
              authorize a person registered with the California Secretary of
              State to act on your behalf.
            </p>
            <p>
              If you are an adult, you can make a verifiable request on behalf
              of a child under your parental authority.
            </p>
            <p>
              You can submit a maximum number of 2 requests over a period of 12
              months.
            </p>

            <h4>How and when we are expected to handle your request</h4>
            <p>
              We will confirm receipt of your verifiable request within 10 days
              and provide information about how we will process your request.
            </p>
            <p>
              We will respond to your request within 45 days of its receipt.
              Should we need more time, we will explain to you the reasons why,
              and how much more time we need. In this regard, please note that
              we may take up to 90 days to fulfill your request.
            </p>
            <p>
              Our disclosure(s) will cover the preceding 12-month period. Only
              with regard to personal information collected on or after January
              1, 2022, you have the right to request that we disclose
              information beyond the 12-month period, and we will provide them
              to you unless doing so proves impossible or would involve a
              disproportionate effort.
            </p>
            <p>
              Should we deny your request, we will explain you the reasons
              behind our denial.
            </p>
            <p>
              We do not charge a fee to process or respond to your verifiable
              request unless such request is manifestly unfounded or excessive.
              In such cases, we may charge a reasonable fee, or refuse to act on
              the request. In either case, we will communicate our choices and
              explain the reasons behind it.
            </p>
          </div>

          <div class="one_line_col">
            <h2 id="virginia_info">
              Further information for Virginia consumers
            </h2>
            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running this Application and, if the
              case may be, its parent, subsidiaries and affiliates (for the
              purposes of this section referred to collectively as “we”, “us”,
              “our”).
            </p>
            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the Commonwealth of Virginia, according to the
              <a
                href="https://law.lis.virginia.gov/vacode/title59.1/chapter53/"
                target="_blank"
                rel="noopener"
              >
                “Virginia Consumer Data Protection Act"</a
              >
              (the "VCDPA"), and, for such consumers, it supersedes any other
              possibly divergent or conflicting information contained in the
              privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the VCDPA.
            </p>

            <h3
              class="iub-subheading iub-subheading-vcdpa-information-collected"
            >
              Categories of personal data processed
            </h3>
            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof.
              <b
                >You can read about these activities in detail in the section
                titled “Detailed information on the processing of Persona Data”
                within this document</b
              >.
            </p>

            <h4>
              Categories of personal data we collect
            </h4>
            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information and internet information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <h4>
              Why we process your personal data
            </h4>
            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>
            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.<br />
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <h4>
              How we use the data we collect: sharing of your personal data with
              third parties
            </h4>
            <p>
              We share your personal data with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document</b
              >. These third parties are grouped and categorized in accordance
              with the different purposes of processing.<br />
              For our purposes, the word "third party" means "a natural or legal
              person, public authority, agency, or body other than the consumer,
              controller, processor, or an affiliate of the processor or the
              controller" as defined by the VCDPA.
            </p>

            <h4>Sale of your personal data</h4>

            <p>
              For our purposes, the word “sale” means any “exchange of personal
              data for monetary consideration by us to a third party“ as defined
              by the VCDPA.
              <br />
              Please note that according to the VCDPA, the disclosure of
              personal data to a processor that processes personal data on
              behalf of a controller does not constitute a sale. In addition,
              other specific exceptions set forth in the VCDPA may apply, such
              as, but not limited to, the disclosure of personal data to a third
              party for the provision of a product or service requested by you.
              <br />
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              information may be considered a sale under VCDPA.
            </p>

            <h5>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </h5>
            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request. To fully exercise your right to opt
              out you can contact us at any time using the contact details
              provided in this document.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <h4>Processing of your personal data for targeted advertising</h4>

            <p>
              We do not process your personal data for targeted advertising. If
              we decide to do so, we will inform you beforehand and will grant
              your right to opt out of the processing of your personal data for
              targeted advertising.
            </p>

            <h3>
              Your privacy rights under the Virginia Consumer Data Protection
              Act and how to exercise them
            </h3>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>
            <ul>
              <li>
                <b>access personal data: the right to know.</b> You have the
                right to request that we confirm whether or not we are
                processing your personal data. You also have the right to access
                such personal data.
              </li>
              <li>
                <b>correct inaccurate personal data.</b> You have the right to
                request that we correct any inaccurate personal data we maintain
                about you, taking into account the nature of the personal data
                and the purposes of the processing of the personal data.
              </li>
              <li>
                <b>request the deletion of your personal data.</b> You have the
                right to request that we delete any of your personal data.
              </li>
              <li>
                <b>obtain a copy of your personal data.</b> We will provide your
                personal data in a portable and usable format that allows you to
                transfer data easily to another entity — provided that this is
                technically feasible.
              </li>
              <li>
                <b
                  >opt out of the processing of your personal data for the
                  purposes of targeted advertising</b
                >, the <b>sale of personal data</b>, or <b>profiling</b> in
                furtherance of decisions that produce legal or similarly
                significant effects concerning you.
              </li>
              <li>
                <b>non-discrimination.</b> We will not discriminate against you
                for exercising your rights under the VCDPA. This means that we
                will not, among other things, deny goods or services, charge you
                a different price, or provide a different level or quality of
                goods or services just because you exercised your consumer
                privacy rights. However, if you refuse to provide your personal
                data to us or ask us to delete or stop selling your personal
                data, and that personal data or sale is necessary for us to
                provide you with goods or services, we may not be able to
                complete that transaction. To the extent permitted by the law,
                we may offer a different price, rate, level, quality, or
                selection of goods or services to you, including offering goods
                or services for no fee, if you have exercised your right to opt
                out, or our offer is related to your voluntary participation in
                a bona fide loyalty, rewards, premium features, discounts, or
                club card program.
              </li>
            </ul>

            <h4>How to exercise your rights</h4>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>
            <p>
              For us to respond to your request, we need to know who you are.
            </p>
            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>
            <p>
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>
            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <h4>How and when we are expected to handle your request</h4>
            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>
            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 60 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied you may
              <a
                href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                target="_blank"
                rel="noopener"
              >
                contact the Attorney General to submit a complaint</a
              >.
            </p>
            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year. If your request is manifestly unfounded,
              excessive or repetitive, we may charge a reasonable fee or refuse
              to act on the request. In either case, we will communicate our
              choices and explain the reasons behind them.
            </p>
          </div>

          <div class="one_line_col">
            <h2 id="colorado_info">
              Further information for Colorado consumers
            </h2>
            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running this Application and, if the
              case may be, its parent, subsidiaries and affiliates (for the
              purposes of this section referred to collectively as “we”, “us”,
              “our”).
            </p>
            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Colorado, according to the “Colorado Privacy Act"
              (the "CPA"), and, for such consumers, it supersedes any other
              possibly divergent or conflicting information contained in the
              privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the CPA.
            </p>

            <h3 class="iub-subheading iub-subheading-cpa-information-collected">
              Categories of personal data processed
            </h3>
            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof.
              <b
                >You can read about these activities in detail in the section
                titled “Detailed information on the processing of Persona Data”
                within this document</b
              >.
            </p>

            <h4>
              Categories of personal data we collect
            </h4>
            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information and internet information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <h4>
              Why we process your personal data
            </h4>
            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>
            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.<br />
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <h4>
              How we use the data we collect: sharing of your personal data with
              third parties
            </h4>
            <p>
              We share your personal data with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document</b
              >. These third parties are grouped and categorized in accordance
              with the different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a person, public
              authority, agency, or body other than a consumer, controller,
              processor, or affiliate of the processor or the controller." as
              defined by the CPA.
            </p>

            <h4>Sale of your personal data</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the CPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              CPA.
            </p>
            <p>
              Please note that according to the CPA, the disclosure of personal
              data to a processor that processes personal data on behalf of a
              controller does not constitute a sale. In addition, other specific
              exceptions set forth in the CPA may apply, such as, but not
              limited to, the disclosure of personal data to a third party for
              the provision of a product or service requested by you.
            </p>

            <h5>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </h5>
            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <h4>Processing of your personal data for targeted advertising</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across nonaffiliated websites, applications,
              or online services to predict consumer preferences or interests"
              as defined by CPA. <br /><br />Please note that according to the
              CPA, targeted advertising does not include: “advertisements
              directed to a consumer in response to the consumer's request for
              information or feedback; advertisements based on activities within
              a controller's own websites or online applications or any
              affiliated website or online application; advertisements based on
              the context of a consumer's current search query, visit to an
              internet web site or online application; or processing personal
              data solely to measure or report advertising frequency,
              performance or reach”.
            </p>

            <h5>
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </h5>
            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <h5>Universal opt-out mechanism: Global privacy control</h5>

            <p>
              If you want to submit requests to opt-out of the sale of personal
              data or the targeted advertising via a user-enabled global privacy
              control, like the Global Privacy Control (“<a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >GPC</a
              >”), you are free to do so and we will abide by such request. The
              GPC consists of a setting or extension in the browser or mobile
              device and acts as a mechanism that websites can use to indicate
              they support the GPC signal. If you want to use GPC, you can
              download and enable it via a participating browser or browser
              extension. More information about downloading GPC is available
              <a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >here</a
              >.
            </p>

            <h3>
              Your privacy rights under the Colorado Privacy Act and how to
              exercise them
            </h3>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>
            <ul>
              <li>
                opt out of the processing of your personal data for the purposes
                of targeted advertising, the sale of personal data, or profiling
                in furtherance of decisions that produce legal or similarly
                significant effects concerning you.
              </li>
              <li>
                access personal data. You have the right to request that we
                confirm whether or not we are processing your personal data. You
                also have the right to access such personal data.
              </li>
              <li>
                correct inaccurate personal data. You have the right to request
                that we correct any inaccurate personal data we maintain about
                you, taking into account the nature of the personal data and the
                purposes of the processing of the personal data.
              </li>
              <li>
                request the deletion of your personal data. You have the right
                to request that we delete any of your personal data.
              </li>
              <li>
                obtain a copy of your personal data. We will provide your
                personal data in a portable and usable format that allows you to
                transfer data easily to another entity – provided that this is
                technically feasible.
              </li>
            </ul>
            <p>
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <h4>How to exercise your rights</h4>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>
            <p>
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>
            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>
            <p>
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>
            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <h4>How and when we are expected to handle your request</h4>
            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>
            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 45 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied you may contact the Attorney General to submit a complaint.
            </p>
            <p>
              We do not charge a fee to respond to your request, for up to two
              requests per year.
            </p>
          </div>

          <div class="one_line_col">
            <h2 id="connecticut_info">
              Further information for Connecticut consumers
            </h2>
            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running this Application and, if the
              case may be, its parent, subsidiaries and affiliates (for the
              purposes of this section referred to collectively as “we”, “us”,
              “our”).
            </p>
            <p>
              This section applies o all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Connecticut, according to “An Act Concerning Personal
              Data Privacy and Online Monitoring " (also known as "The
              Connecticut Data Privacy Act" or the “CTDPA"), and, for such
              consumers, it supersedes any other possibly divergent or
              conflicting information contained in the privacy policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the CTDPA.
            </p>

            <h3
              class="iub-subheading iub-subheading-ctdpa-information-collected"
            >
              Categories of personal data processed
            </h3>
            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof.
              <b
                >You can read about these activities in detail in the section
                titled “Detailed information on the processing of Persona Data”
                within this document</b
              >.
            </p>

            <h4>
              Categories of personal data we collect
            </h4>
            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information and internet information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <h4>
              Why we process your personal data
            </h4>
            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>
            <p>
              We won’t process your information for unexpected purposes, or for
              purposes incompatible with the purposes originally disclosed,
              without your consent.<br />
              You can freely give, deny, or withdraw such consent at any time
              using the contact details provided in this document.
            </p>

            <h4>
              How we use the data we collect: sharing of your personal data with
              third parties
            </h4>
            <p>
              We share your personal data with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document</b
              >. These third parties are grouped and categorized in accordance
              with the different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a person, public
              authority, agency, or body other than a consumer, controller,
              processor, or affiliate of the processor or the controller." as
              defined by the CTDPA.
            </p>

            <h4>Sale of your personal data</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the CTDPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              CTDPA.
            </p>
            <p>
              Please note that according to the CTDPA, the disclosure of
              personal data to a processor that processes personal data on
              behalf of a controller does not constitute a sale. In addition,
              other specific exceptions set forth in the CTDPA may apply, such
              as, but not limited to, the disclosure of personal data to a third
              party for the provision of a product or service requested by you.
            </p>

            <h5>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </h5>
            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <h4>Processing of your personal data for targeted advertising</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across non affiliated websites,
              applications, or online services to predict consumer preferences
              or interests" as defined by CTDPA. <br /><br />Please note that
              according to the CTDPA, targeted advertising does not include:
              “advertisements based on activities within a controller's own web
              sites or online applications; advertisements based on the context
              of a consumer's current search query, visit to an internet web
              site or online application; advertisements directed to a consumer
              in response to the consumer's request for information or feedback;
              or processing personal data solely to measure or report
              advertising frequency, performance or reach”.
            </p>

            <h5>
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </h5>
            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <h5>Universal opt-out mechanism: Global privacy control</h5>

            <p>
              If you want to submit requests to opt-out of the sale of personal
              data or the targeted advertising via a user-enabled global privacy
              control, like the Global Privacy Control (“<a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >GPC</a
              >”), you are free to do so and we will abide by such request. The
              GPC consists of a setting or extension in the browser or mobile
              device and acts as a mechanism that websites can use to indicate
              they support the GPC signal. If you want to use GPC, you can
              download and enable it via a participating browser or browser
              extension. More information about downloading GPC is available
              <a
                href="https://globalprivacycontrol.org/"
                target="_blank"
                rel="noopener"
                >here</a
              >.
            </p>

            <h3>
              Your privacy rights under the Connecticut Data Privacy Act and how
              to exercise them
            </h3>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>
            <ul>
              <li>
                access personal data. You have the right to request that we
                confirm whether or not we are processing your personal data. You
                also have the right to access such personal data.
              </li>
              <li>
                correct inaccurate personal data. You have the right to request
                that we correct any inaccurate personal data we maintain about
                you, taking into account the nature of the personal data and the
                purposes of the processing of the personal data.
              </li>
              <li>
                request the deletion of your personal data. You have the right
                to request that we delete any of your personal data.
              </li>
              <li>
                obtain a copy of your personal data. We will provide your
                personal data in a portable and usable format that allows you to
                transfer data easily to another entity – provided that this is
                technically feasible.
              </li>
              <li>
                opt out of the processing of your personal data for the purposes
                of targeted advertising, the sale of personal data, or profiling
                in furtherance of decisions that produce legal or similarly
                significant effects concerning you.
              </li>
            </ul>
            <p>
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <h4>How to exercise your rights</h4>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>
            <p>
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>
            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request.
            </p>
            <p>
              Making a consumer request does not require you to create an
              account with us. However, we may require you to use your existing
              account. We will use any personal data collected from you in
              connection with your request solely for the purposes of
              authentication, without further disclosing the personal data,
              retaining it longer than necessary for purposes of authentication,
              or using it for unrelated purposes.
            </p>
            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <h4>How and when we are expected to handle your request</h4>
            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>
            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request. It is your right
              to appeal such decision by submitting a request to us via the
              details provided in this document. Within 45 days of receipt of
              the appeal, we will inform you in writing of any action taken or
              not taken in response to the appeal, including a written
              explanation of the reasons for the decisions. If the appeal is
              denied, you may
              <a
                href="https://portal.ct.gov/AG/Common/Complaint-Form-Landing-page"
                target="_blank"
                rel="noopener"
              >
                contact the Attorney General to submit a complaint</a
              >.
            </p>
            <p>
              We do not charge a fee to respond to your request, for up to one
              request per year.
            </p>
          </div>

          <div class="one_line_col">
            <h2 id="utah_info">Further information for Utah consumers</h2>
            <p>
              This section of the document integrates with and supplements the
              information contained in the rest of the privacy policy and is
              provided by the controller running this Application and, if the
              case may be, its parent, subsidiaries and affiliates (for the
              purposes of this section referred to collectively as “we”, “us”,
              “our”).
            </p>
            <p>
              This section applies to all Users (Users are referred to below,
              simply as “you”, “your”, “yours”), who are consumers residing in
              the State of Utah, according to the “Consumer Privacy Act" (the
              “UCPA"), and, for such consumers, it supersedes any other possibly
              divergent or conflicting information contained in the privacy
              policy.
            </p>

            <p>
              This part of the document uses the term “personal data” as defined
              in the UCPA.
            </p>

            <h3
              class="iub-subheading iub-subheading-ucpa-information-collected"
            >
              Categories of personal data processed
            </h3>
            <p>
              In this section, we summarize the categories of personal data that
              we've processed and the purposes thereof.
              <b
                >You can read about these activities in detail in the section
                titled “Detailed information on the processing of Persona Data”
                within this document</b
              >.
            </p>

            <h4>
              Categories of personal data we collect
            </h4>
            <p>
              We have collected the following categories of personal data:
              identifiers, commercial information and internet information
            </p>

            <p>We do not collect sensitive data.</p>

            <p>
              We will not collect additional categories of personal data without
              notifying you.
            </p>

            <h4>
              Why we process your personal data
            </h4>
            <p>
              To find out why we process your personal data, you can read the
              sections titled “Detailed information on the processing of
              Personal Data” and “The purposes of processing” within this
              document.
            </p>

            <h4>
              How we use the data we collect: sharing of your personal data with
              third parties
            </h4>
            <p>
              We share your personal data with the third parties
              <b
                >listed in detail in the section titled “Detailed information on
                the processing of Personal Data” within this document</b
              >. These third parties are grouped and categorized in accordance
              with the different purposes of processing.
            </p>

            <p>
              For our purposes, the word "third party" means "a person other
              than: the consumer, controller, or processor; or an affiliate or
              contractor of the controller or the processor" as defined by the
              UCPA.
            </p>

            <h4>Sale of your personal data</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, our use of your personal
              data may be considered a sale under the UCPA.
            </p>

            <p>
              For our purposes, the word "sale", "sell", or "sold" means "the
              exchange of personal data for monetary or other valuable
              consideration by a controller to a third party" as defined by the
              UCPA.
            </p>
            <p>
              Please note that according to the UCPA, the disclosure of personal
              data to a processor that processes personal data on behalf of a
              controller does not constitute a sale. In addition, other specific
              exceptions set forth in the UCPA may apply, such as, but not
              limited to, the disclosure of personal data to a third party for
              the provision of a product or service requested by you.
            </p>

            <h5>
              Your right to opt out of the sale of your personal data and how
              you can exercise it
            </h5>
            <p>
              You have the right to opt out of the sale of your personal data.
              This means that whenever you request us to stop selling your data,
              we will abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purpose of
              complying with the request.
            </p>

            <h4>Processing of your personal data for targeted advertising</h4>

            <p>
              As specified in the “Detailed information on the processing of
              Personal Data” section of this document, we may use your personal
              data for targeted advertising purposes.
            </p>

            <p>
              For our purposes, the word "targeted advertising" means
              "displaying to a consumer an advertisement that is selected based
              on personal data obtained or inferred over time from the
              consumer's activities across nonaffiliated websites, applications,
              or online services to predict consumer preferences or interests"
              as defined by UCPA. <br /><br />Please note that according to the
              UCPA, targeted advertising does not include: “advertisements based
              on activities within a controller's own websites or online
              applications or any affiliated website or online application;
              advertisements based on the context of a consumer's current search
              query, visit to an web site or online application; advertisements
              directed to a consumer in response to the consumer's request for
              information, product, a service or feedback; or processing
              personal data solely to measure or report advertising performance,
              reach or frequency.”
            </p>

            <h5>
              Your right to opt out of the processing of your personal data for
              targeted advertising and how you can exercise it
            </h5>
            <p>
              You have the right to opt out of the processing of your personal
              data for targeted advertising. This means that whenever you ask us
              to stop processing your data for targeted advertising, we will
              abide by your request.
            </p>
            <p>
              To fully exercise your right to opt out you can contact us at any
              time, using the contact details provided in this document.
            </p>
            <p>
              For a simplified opt-out method you can also use the privacy
              choices link provided on this Application.
            </p>
            <p>
              We use any personal data collected from you in connection with the
              submission of your opt-out request solely for the purposes of
              complying with the opt-out request.
            </p>

            <h3>
              Your privacy rights under the Utah Consumer Privacy Act and how to
              exercise them
            </h3>

            <p>
              You may exercise certain rights regarding your data processed by
              us. In particular, you have the right to do the following:
            </p>
            <ul>
              <li>
                access personal data. You have the right to request that we
                confirm whether or not we are processing your personal data. You
                also have the right to access such personal data.
              </li>
              <li>
                request the deletion of your personal data. You have the right
                to request that we delete any of your personal data.
              </li>
              <li>
                obtain a copy of your personal data. We will provide your
                personal data in a portable and usable format that allows you to
                transfer data easily to another entity – provided that this is
                technically feasible.
              </li>
              <li>
                opt out of the processing of your personal data for the purposes
                of targeted advertising or the sale of personal data.
              </li>
            </ul>
            <p>
              In any case, we will not increase the cost of, or decrease the
              availability of, a product or service, based solely on the
              exercise of any of your rights and unrelated to the feasibility or
              the value of a service. However, to the extent permitted by the
              law, we may offer a different price, rate, level, quality, or
              selection of goods or services to you, including offering goods or
              services for no fee, if our offer is related to your voluntary
              participation in a bona fide loyalty, rewards, premium features,
              discounts, or club card program.
            </p>

            <h4>How to exercise your rights</h4>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document.
            </p>
            <p>
              For us to respond to your request, we need to know who you are and
              which right you wish to exercise.
            </p>
            <p>
              We will not respond to any request if we are unable to verify your
              identity using commercially reasonable efforts and therefore
              confirm that the personal data in our possession actually relate
              to you. In such cases, we may request that you provide additional
              information which is reasonably necessary to authenticate you and
              your request. We may retain your email address to respond to your
              request.
            </p>
            <p>
              If you are an adult, you can make a request on behalf of a child
              under your parental authority.
            </p>

            <h4>How and when we are expected to handle your request</h4>
            <p>
              We will respond to your request without undue delay, but in all
              cases and at the latest within 45 days of its receipt. Should we
              need more time, we will explain to you the reasons why, and how
              much more time we need. In this regard, please note that we may
              take up to 90 days to fulfill your request.
            </p>
            <p>
              Should we deny your request, we will explain to you the reasons
              behind our denial without undue delay, but in all cases and at the
              latest within 45 days of receipt of the request.
            </p>
            <p>
              We do not charge a fee to respond to your request, for up to one
              request per year.
            </p>
          </div>

          <div class="one_line_col">
            <h2 id="further_data_processing_info">
              Additional information about Data collection and processing
            </h2>
            <h3 class="iub-subheading iub-subheading-legal-protection">
              Legal action
            </h3>
            <p>
              The User's Personal Data may be used for legal purposes by the
              Owner in Court or in the stages leading to possible legal action
              arising from improper use of this Application or the related
              Services.<br />The User declares to be aware that the Owner may be
              required to reveal personal data upon request of public
              authorities.
            </p>
            <h3 class="iub-subheading iub-subheading-legal-specific-info">
              Additional information about User's Personal Data
            </h3>
            <p>
              In addition to the information contained in this privacy policy,
              this Application may provide the User with additional and
              contextual information concerning particular Services or the
              collection and processing of Personal Data upon request.
            </p>
            <h3 class="iub-subheading iub-subheading-legal-maintenance">
              System logs and maintenance
            </h3>
            <p>
              For operation and maintenance purposes, this Application and any
              third-party services may collect files that record interaction
              with this Application (System logs) or use other Personal Data
              (such as the IP Address) for this purpose.
            </p>
            <h3
              class="iub-subheading iub-subheading-legal-information-not-contained"
            >
              Information not contained in this policy
            </h3>
            <p>
              More details concerning the collection or processing of Personal
              Data may be requested from the Owner at any time. Please see the
              contact information at the beginning of this document.
            </p>

            <h3
              class="iub-subheading iub-subheading-legal-changes-to-this-privacy-policy"
            >
              Changes to this privacy policy
            </h3>
            <p>
              The Owner reserves the right to make changes to this privacy
              policy at any time by notifying its Users on this page and
              possibly within this Application and/or - as far as technically
              and legally feasible - sending a notice to Users via any contact
              information available to the Owner. It is strongly recommended to
              check this page often, referring to the date of the last
              modification listed at the bottom. <br /><br />
              Should the changes affect processing activities performed on the
              basis of the User’s consent, the Owner shall collect new consent
              from the User, where required.
            </p>
          </div>

          <div class="one_line_col">
            <div class="box_primary box_10 definitions expand">
              <h3
                id="definitions_and_legal_references"
                class="expand-click w_icon_24 icon_ribbon"
              >
                Definitions and legal references
              </h3>
              <div class="expand-content">
                <h4>Personal Data (or Data)</h4>
                <p>
                  Any information that directly, indirectly, or in connection
                  with other information — including a personal identification
                  number — allows for the identification or identifiability of a
                  natural person.
                </p>

                <h4>Usage Data</h4>
                <p>
                  Information collected automatically through this Application
                  (or third-party services employed in this Application), which
                  can include: the IP addresses or domain names of the computers
                  utilized by the Users who use this Application, the URI
                  addresses (Uniform Resource Identifier), the time of the
                  request, the method utilized to submit the request to the
                  server, the size of the file received in response, the
                  numerical code indicating the status of the server's answer
                  (successful outcome, error, etc.), the country of origin, the
                  features of the browser and the operating system utilized by
                  the User, the various time details per visit (e.g., the time
                  spent on each page within the Application) and the details
                  about the path followed within the Application with special
                  reference to the sequence of pages visited, and other
                  parameters about the device operating system and/or the User's
                  IT environment.
                </p>

                <h4>User</h4>
                <p>
                  The individual using this Application who, unless otherwise
                  specified, coincides with the Data Subject.
                </p>

                <h4>Data Subject</h4>
                <p>The natural person to whom the Personal Data refers.</p>

                <h4>Data Processor (or Processor)</h4>
                <p>
                  The natural or legal person, public authority, agency or other
                  body which processes Personal Data on behalf of the
                  Controller, as described in this privacy policy.
                </p>

                <h4>Data Controller (or Owner)</h4>
                <p>
                  The natural or legal person, public authority, agency or other
                  body which, alone or jointly with others, determines the
                  purposes and means of the processing of Personal Data,
                  including the security measures concerning the operation and
                  use of this Application. The Data Controller, unless otherwise
                  specified, is the Owner of this Application.
                </p>

                <h4>This Application</h4>
                <p>
                  The means by which the Personal Data of the User is collected
                  and processed.
                </p>

                <h4>Service</h4>
                <p>
                  The service provided by this Application as described in the
                  relative terms (if available) and on this site/application.
                </p>

                <h4>European Union (or EU)</h4>
                <p>
                  Unless otherwise specified, all references made within this
                  document to the European Union include all current member
                  states to the European Union and the European Economic Area.
                </p>

                <h4>Cookie</h4>
                <p>
                  Cookies are Trackers consisting of small sets of data stored
                  in the User's browser.
                </p>

                <h4>Tracker</h4>
                <p>
                  Tracker indicates any technology - e.g Cookies, unique
                  identifiers, web beacons, embedded scripts, e-tags and
                  fingerprinting - that enables the tracking of Users, for
                  example by accessing or storing information on the User’s
                  device.
                </p>
                <hr />
                <h4>Legal information</h4>
                <p>
                  This privacy statement has been prepared based on provisions
                  of multiple legislations.
                </p>
                <p>
                  This privacy policy relates solely to this Application, if not
                  stated otherwise within this document.
                </p>
              </div>
            </div>
          </div>

          <div class="iub_footer">
            <p>
              Latest update: November 06, 2023
            </p>

            <p>
              <a
                target="_top"
                href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                title="iubenda - Privacy Policy generator"
                >iubenda</a
              >
              hosts this content and only collects
              <a target="_top" href="https://www.iubenda.com/privacy-policy/65675001"
                >the Personal Data strictly necessary</a
              >
              for it to be provided.
            </p>
          </div>
          <!-- /footer -->
        </div>
        <!-- /content -->
      </div>
      <!-- /wbars -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style>
html#iubenda_policy,#iubenda_policy body {
    margin: 0;
    padding: 0
}

html#iubenda_policy {
    overflow-y: scroll;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6,#iubenda_policy p,#iubenda_policy blockquote,#iubenda_policy pre,#iubenda_policy a,#iubenda_policy abbr,#iubenda_policy acronym,#iubenda_policy address,#iubenda_policy cite,#iubenda_policy code,#iubenda_policy del,#iubenda_policy dfn,#iubenda_policy em,#iubenda_policy img,#iubenda_policy q,#iubenda_policy s,#iubenda_policy samp,#iubenda_policy small,#iubenda_policy strike,#iubenda_policy strong,#iubenda_policy sub,#iubenda_policy sup,#iubenda_policy tt,#iubenda_policy var,#iubenda_policy dd,#iubenda_policy dl,#iubenda_policy dt,#iubenda_policy li,#iubenda_policy ol,#iubenda_policy ul,#iubenda_policy fieldset,#iubenda_policy form,#iubenda_policy label,#iubenda_policy legend,#iubenda_policy button,#iubenda_policy table,#iubenda_policy caption,#iubenda_policy tbody,#iubenda_policy tfoot,#iubenda_policy thead,#iubenda_policy tr,#iubenda_policy th,#iubenda_policy td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: Arial
}

#iubenda_policy table {
    border-collapse: collapse;
    border-spacing: 0
}

#iubenda_policy ol,#iubenda_policy ul {
    list-style: none
}

#iubenda_policy q:before,#iubenda_policy q:after,#iubenda_policy blockquote:before,#iubenda_policy blockquote:after {
    content: ""
}

#iubenda_policy a:focus {
    outline: thin dotted
}

#iubenda_policy a:hover,#iubenda_policy a:active {
    outline: 0
}

#iubenda_policy article,#iubenda_policy aside,#iubenda_policy details,#iubenda_policy figcaption,#iubenda_policy figure,#iubenda_policy footer,#iubenda_policy header,#iubenda_policy hgroup,#iubenda_policy nav,#iubenda_policy section {
    display: block
}

#iubenda_policy audio,#iubenda_policy canvas,#iubenda_policy video {
    display: inline-block;
    *display: inline;
    *zoom:1}

#iubenda_policy audio:not([controls]) {
    display: none
}

#iubenda_policy sub,#iubenda_policy sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

#iubenda_policy sup {
    top: -0.5em
}

#iubenda_policy sub {
    bottom: -0.25em
}

#iubenda_policy img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

#iubenda_policy button,#iubenda_policy input,#iubenda_policy select,#iubenda_policy textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle
}

#iubenda_policy button,#iubenda_policy input {
    line-height: normal;
    *overflow: visible
}

#iubenda_policy button::-moz-focus-inner,#iubenda_policy input::-moz-focus-inner {
    border: 0;
    padding: 0
}

#iubenda_policy button,#iubenda_policy input[type="button"],#iubenda_policy input[type="reset"],#iubenda_policy input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button
}

#iubenda_policy input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

#iubenda_policy input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

#iubenda_policy textarea {
    overflow: auto;
    vertical-align: top
}

html#iubenda_policy {
    -webkit-font-smoothing: antialiased
}

#iubenda_policy p {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 9px
}

#iubenda_policy p small {
    font-size: 11px;
    color: #bfbfbf
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6 {
    font-weight: bold;
    color: #505762
}

#iubenda_policy h1 {
    margin-bottom: 18px;
    font-size: 30px;
    line-height: 2
}

#iubenda_policy h1 small {
    font-size: 18px
}

#iubenda_policy h2 {
    font-size: 24px;
    margin-bottom: 18px;
    line-height: 1.5
}

#iubenda_policy h2 small {
    font-size: 14px
}

#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6 {
    margin-bottom: 9px
}

#iubenda_policy h3 {
    font-size: 18px
}

#iubenda_policy h3 small {
    font-size: 14px
}

#iubenda_policy h4 {
    font-size: 16px
}

#iubenda_policy h4 small {
    font-weight: bold;
    font-size: 13px
}

#iubenda_policy h5 {
    font-size: 13px;
    padding-top: 19px
}

#iubenda_policy h6 {
    font-size: 13px;
    color: #bfbfbf;
    text-transform: uppercase
}

#iubenda_policy ul ul,#iubenda_policy ul ol,#iubenda_policy ol ol,#iubenda_policy ol ul {
    margin: 0
}

#iubenda_policy ul.styled,#iubenda_policy ul {
    list-style: disc;
    padding-top: 5px
}

#iubenda_policy ul.styled li,#iubenda_policy ul li {
    list-style: disc;
    line-height: 19px;
    font-size: 13px;
    margin-left: 30px;
    margin-top: 2px
}

#iubenda_policy ol {
    list-style: decimal
}

#iubenda_policy ul.unstyled {
    list-style: none;
    margin-left: 0
}

#iubenda_policy dl {
    margin-bottom: 18px
}

#iubenda_policy dl dt,#iubenda_policy dl dd {
    line-height: 18px
}

#iubenda_policy dl dt {
    font-weight: bold
}

#iubenda_policy dl dd {
    margin-left: 9px
}

#iubenda_policy hr {
    margin: 0 0 19px;
    border: 0;
    border-bottom: 1px solid #eee
}

#iubenda_policy strong {
    font-style: inherit;
    font-weight: bold
}

#iubenda_policy em {
    font-style: italic;
    font-weight: inherit;
    line-height: inherit
}

#iubenda_policy .muted {
    color: #bfbfbf
}

#iubenda_policy blockquote {
    margin-bottom: 18px;
    border-left: 5px solid #eee;
    padding-left: 15px
}

#iubenda_policy blockquote p {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin-bottom: 0
}

#iubenda_policy blockquote small {
    display: block;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #bfbfbf
}

#iubenda_policy blockquote small:before {
    content: '\2014 \00A0'
}

#iubenda_policy address {
    display: block;
    line-height: 18px;
    margin-bottom: 18px
}

#iubenda_policy code,#iubenda_policy pre {
    padding: 0 3px 2px;
    font-family: Arial;
    font-size: 12px;
    border-radius: 3px
}

#iubenda_policy code {
    background-color: #fee9cc;
    color: rgba(0,0,0,0.75);
    padding: 1px 3px
}

#iubenda_policy pre {
    background-color: #f5f5f5;
    display: block;
    padding: 17px;
    margin: 0 0 18px;
    line-height: 18px;
    font-size: 12px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

#iubenda_policy .breadcrumbs {
    padding: 0 0 10px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #F6F6F6;
    width: 100%
}

#iubenda_policy .breadcrumbs>li {
    float: left;
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5
}

#iubenda_policy .breadcrumbs>li:not(:last-child):after {
    color: #333B43;
    padding: 0 10px;
    content: "\203a"
}

#iubenda_policy .breadcrumbs+.pills,#iubenda_policy .breadcrumbs+.sec_tabs {
    margin-top: -15px
}

#iubenda_policy .table {
    display: table;
    border-collapse: collapse;
    padding: 0 !important;
    margin: 0
}

#iubenda_policy .cust_row {
    display: table-row;
    margin: 0
}

#iubenda_policy .column {
    display: table-cell;
    vertical-align: top;
    padding: 30px
}

#iubenda_policy .box_primary {
    border: 1px solid #C0C1C1;
    border-bottom-color: #A8AAAB;
    -webkit-box-shadow: 0 1px 0 #EBEBEC;
    box-shadow: 0 1px 0 #EBEBEC;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.1);
    box-shadow: 0 1px 0 rgba(0,0,0,0.1);
    background: #FFF
}

#iubenda_policy .box_content {
    border-radius: 4px;
    padding: 30px
}

#iubenda_policy .box_content .iub_content {
    padding: 30px
}

#iubenda_policy .box_content .iub_content>hr {
    width: 686px;
    margin-left: -30px;
    margin-right: -30px
}

#iubenda_policy .box_content .aside {
    width: 191px;
    padding: 30px
}

#iubenda_policy .box_content .aside.aside-right {
    border-left: 1px solid #DFDFDF
}

#iubenda_policy .table>.box_content {
    padding: 0
}

#iubenda_policy .box_10 {
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 15px
}

#iubenda_policy .box_10>h4 {
    margin-bottom: 0;
    font-size: 13px
}

#iubenda_policy .box_10>.w_icon,#iubenda_policy .box_10>.w_icon.expand-click,#iubenda_policy .box_10.expand>.w_icon,#iubenda_policy .box_10.expand>.w_icon.expand-click {
    padding-left: 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 10px;
    background-position-y: 7px;
    background-position: 10px 7px
}

#iubenda_policy .box_10>.w_icon_16,#iubenda_policy .box_10>.w_icon_16.expand-click,#iubenda_policy .box_10.expand>.w_icon_16,#iubenda_policy .box_10.expand>.w_icon_16.expand-click {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 11px;
    background-position-y: 11px;
    background-position: 11px 11px
}

#iubenda_policy .box_10>.w_icon_24,#iubenda_policy .box_10>.w_icon_24.expand-click,#iubenda_policy .box_10.expand>.w_icon_24,#iubenda_policy .box_10.expand>.w_icon_24.expand-click {
    padding-left: 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 10px;
    background-position-y: 10px;
    background-position: 10px 10px
}

#iubenda_policy .box_5 {
    padding: 5px;
    border-radius: 3px;
    font-size: 11px;
    margin-bottom: 15px
}

#iubenda_policy .box_5 hr {
    padding-top: 5px;
    margin: 0 -5px 5px -5px;
    border: 0;
    border-bottom: 1px solid #AC3737
}

#iubenda_policy .box_5.w_icon_16 {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 6px;
    background-position: 8px 6px
}

#iubenda_policy .box_5.w_icon_16 hr {
    width: 100%;
    padding-left: 30px;
    padding-right: 5px;
    margin-left: -30px;
    margin-right: -5px
}

#iubenda_policy .box_5.w_icon_16.red {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAATlBMVEUAAAD%2F%2F%2F8AAAD%2F%2F%2F8AAAAAAAD%2F%2F%2F%2F%2F%2F%2F%2FT09P%2F%2F%2F%2F9%2Ff3Y2Nj9%2Ff39%2Ff3d3d3%2F%2F%2F%2F8%2FPz39%2Ff19fX%2B%2Fv79%2Ff34%2BPj5%2Bfn8%2FPz9%2Ff3%2F%2F%2F8ZO4GEAAAAGXRSTlMAEB0gMDNAUHSAgYSRoaWwsra3weLl5fLyUJhrdwAAAF1JREFUeF6NzUcWhCAAwFAQsIPOWCD3v6gPxLYjy7%2BJKE1Ok%2FxAD%2BMbFIB6wYIxLA%2FUbEJAc8PKHmG9oAOkArq8DICdgXCuLUA7EDkBsd%2BfWALnyXmXoNImpytR0AEwdQcUE5t8VQAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .box_thumb {
    background: #FFF;
    -webkit-box-shadow: 0 0 1px #A3A3A3, 0 1px 1px #A3A3A3;
    box-shadow: 0 0 1px #A3A3A3, 0 1px 1px #A3A3A3;
    padding: 6px
}

#iubenda_policy footer {
    margin-top: 17px;
    padding-top: 17px;
    border-top: 1px solid #eee
}

#iubenda_policy hr {
    padding-top: 15px;
    margin: 0 0 15px 0
}

#iubenda_policy hr.primary {
    border: 0;
    border-bottom: 1px solid #DFDFDF;
    -webkit-box-shadow: 0 1px 0 #F7F7F7;
    box-shadow: 0 1px 0 #F7F7F7
}

#iubenda_policy .btn {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    background-color: rgba(0,0,0,0.08);
    padding: 0 20px;
    line-height: 38px;
    color: #54616B;
    font-size: 13px;
    border: 0;
    border-radius: 4rem;
    border-collapse: separate;
    -webkit-transition: 0.1s linear all;
    transition: 0.1s linear all
}

#iubenda_policy .btn:hover {
    background-position: 0 -15px;
    text-decoration: none
}

#iubenda_policy .btn:focus {
    outline: 1px dotted #666
}

#iubenda_policy .btn:active {
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,0.25),0 1px 2px rgba(0,0,0,0.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.25),0 1px 2px rgba(0,0,0,0.05)
}

#iubenda_policy .btn.disabled {
    cursor: default;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.65;
    -moz-opacity: 0.65;
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .btn[disabled] {
    cursor: default;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.65;
    -moz-opacity: 0.65;
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .btn.large {
    font-size: 16px;
    line-height: normal;
    padding: 0 14px;
    border-radius: 6px
}

#iubenda_policy .btn.mid_large {
    padding: 0 10px;
    line-height: 32px;
    font-size: 13px
}

#iubenda_policy .btn.mid {
    padding: 0 10px;
    line-height: 28px;
    font-size: 11px
}

#iubenda_policy .btn.small {
    padding: 0 8px;
    line-height: 18px;
    font-size: 11px
}

#iubenda_policy :root .alert-message,#iubenda_policy :root .btn {
    border-radius: 0 \0
}

#iubenda_policy button.btn::-moz-focus-inner,#iubenda_policy input.btn[type=submit]::-moz-focus-inner {
    padding: 0;
    border: 0
}

#iubenda_policy .circle {
    font-size: 11px;
    line-height: 18px;
    width: 18px;
    padding: 0;
    text-align: center;
    border-radius: 11px
}

#iubenda_policy .circle.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0
}

#iubenda_policy .blue,#iubenda_policy .btn.primary {
    color: #fff;
    background-color: #0073CE
}

#iubenda_policy .yellow {
    color: #6D693D;
    background-color: #FFD24D
}

#iubenda_policy .red {
    color: #FFF;
    background-color: #FF5D4D
}

#iubenda_policy .red a,#iubenda_policy .red a:hover:not(.btn) {
    color: #FFF
}

#iubenda_policy .red a {
    border-bottom-color: rgba(247,247,247,0.3)
}

#iubenda_policy .red a:hover {
    border-bottom-color: rgba(247,247,247,0.6)
}

#iubenda_policy .green {
    color: #4D6C47;
    background-color: #F1FFD5
}

#iubenda_policy .iubgreen {
    color: #01281B;
    background-color: #1CC691
}

#iubenda_policy .azure {
    color: #364048;
    background-color: #D2ECFE
}

#iubenda_policy .white {
    color: #54616B;
    background-color: #F8F8F8
}

#iubenda_policy .black {
    color: #FFF;
    background-color: #333333
}

#iubenda_policy .trasp {
    color: #333B43;
    background-color: #FFFFFF
}

#iubenda_policy .alert-message {
    position: relative;
    padding: 7px 15px;
    margin-bottom: 18px;
    color: #404040;
    background-color: #eedc94;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
    background-image: linear-gradient(to bottom, #fceec1, #eedc94);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startColor', endColorstr='$endColor', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
    border-color: #eedc94 #eedc94 #e4c652;
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) fadein(rgba(0,0,0,0.1), 15%);
    text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.25)
}

#iubenda_policy .alert-message .close {
    *margin-top: 3px
}

#iubenda_policy .alert-message h5 {
    line-height: 18px
}

#iubenda_policy .alert-message p {
    margin-bottom: 0
}

#iubenda_policy .alert-message div {
    margin-top: 5px;
    margin-bottom: 2px;
    line-height: 28px
}

#iubenda_policy .alert-message .btn {
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.25);
    box-shadow: 0 1px 0 rgba(255,255,255,0.25)
}

#iubenda_policy .alert-message.block-message {
    background-image: none;
    background-color: #fdf5d9;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    padding: 14px;
    border-color: #fceec1;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .alert-message.block-message ul,#iubenda_policy .alert-message.block-message p {
    margin-right: 30px
}

#iubenda_policy .alert-message.block-message ul {
    margin-bottom: 0
}

#iubenda_policy .alert-message.block-message li {
    color: #404040
}

#iubenda_policy .alert-message.block-message .alert-actions {
    margin-top: 5px
}

#iubenda_policy .alert-message.block-message.error,#iubenda_policy .alert-message.block-message.success,#iubenda_policy .alert-message.block-message.info {
    color: #404040;
    text-shadow: 0 1px 0 rgba(255,255,255,0.5)
}

#iubenda_policy .alert-message.block-message.error {
    background-color: #fddfde;
    border-color: #fbc7c6
}

#iubenda_policy .alert-message.block-message.success {
    background-color: #d1eed1;
    border-color: #bfe7bf
}

#iubenda_policy .alert-message.block-message.info {
    background-color: #ddf4fb;
    border-color: #c6edf9
}

#iubenda_policy .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    opacity: 0
}

#iubenda_policy .fade.in {
    opacity: 1
}

#iubenda_policy .expand-click {
    cursor: pointer;
    position: relative
}

#iubenda_policy .box_10.expand .expand-click {
    margin: -10px;
    padding: 12px 25px 13px 10px
}

#iubenda_policy .box_10.expand .expand-content {
    margin-top: 10px
}

#iubenda_policy .box_10.expand .expand-content>*:first-child {
    margin-top: 0;
    padding-top: 0
}

#iubenda_policy .expand.expanded .expand-click:after,#iubenda_policy .box_10.expand.expanded .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 19px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-top-color: #333B43
}

#iubenda_policy .expand .expand-click,#iubenda_policy .expand.expanded .expand-click,#iubenda_policy .box_10.expand .expand-click,#iubenda_policy .box_10.expand.expanded .expand-click {
    border-bottom: 1px dotted #DDD;
    margin-bottom: 10px;
    -webkit-transition: 0.2s linear all;
    transition: 0.2s linear all
}

#iubenda_policy .expand.collapsed .expand-click {
    border-bottom: 0;
    margin-bottom: -10px
}

#iubenda_policy .expand.collapsed .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 17px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-right-color: #333B43
}

#iubenda_policy .all-collapsed .expand .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 17px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-right-color: #333B43
}

#iubenda_policy .all-collapsed .expand .expand-click {
    border-bottom: 0;
    margin-bottom: -10px
}

#iubenda_policy .all-collapsed .expand-content {
    display: none
}

html#iubenda_policy,#iubenda_policy body {
    background-color: #FFF
}

#iubenda_policy {
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    color: #505762
}

#iubenda_policy body {
    margin: 0
}

#iubenda_policy .iub_container-fluid {
    position: relative;
    min-width: 940px;
    padding-left: 20px;
    padding-right: 20px;
    zoom:1}

#iubenda_policy .iub_container-fluid:before,#iubenda_policy .iub_container-fluid:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .iub_container-fluid:after {
    clear: both
}

#iubenda_policy .iub_container-fluid>.sidebar {
    float: left;
    width: 220px
}

#iubenda_policy .iub_container-fluid>.iub_content {
    margin-left: 240px
}

#iubenda_policy a {
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid #F6F6F6;
    color: #333B43
}

#iubenda_policy a.unstyled {
    border-bottom: 0
}

#iubenda_policy a:hover:not(.btn) {
    color: #121518;
    border-bottom-color: #D6D6D6;
    -webkit-transition: 0.1s linear all;
    transition: 0.1s linear all
}

#iubenda_policy a.no_border,#iubenda_policy a.no_border:hover {
    border-bottom-width: 0
}

#iubenda_policy .pull-right {
    float: right
}

#iubenda_policy .pull-left {
    float: left
}

#iubenda_policy .hide {
    display: none
}

#iubenda_policy .show {
    display: block
}

#iubenda_policy .link_on_dark a {
    border-bottom-color: rgba(247,247,247,0.3)
}

#iubenda_policy .link_on_dark a:hover {
    border-bottom-color: rgba(247,247,247,0.6)
}

#iubenda_policy [class*="policyicon_"] {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC)
}

#iubenda_policy .policyicon_pdt_68 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .policyicon_purpose_5 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAElBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2BtTDCxAAAABXRSTlMAECBAgLf%2B2%2BsAAABGSURBVBhXY2AAA5ZQBwY4YA0NIJfjCjYHygkNDUTmBGPhgOyFc1iB6pE4wSAOUAGCIxoaiOCYhgYjOKqhQThkyODAAR4OAI98N9LK6tL3AAAAAElFTkSuQmCC)
}

#iubenda_policy .policyicon_purpose_7 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAINJREFUeF6V0UsOxCAIBmB8tVoZ4f6nnUqaoFUW%2FVeEj0hUMOKM9kE7CBcxr93SuGcCf%2FRZniCmXGVUwZV2M78DgYRXQDaAP0OzIJIB4C%2FaQo%2BTCyK9ISFizimAPyuNACjlKXW6SMF30B9I9YFndRieuZCCHKU0QIU1LDEhrvDrQG6EP%2FDZElAL0vLHAAAAAElFTkSuQmCC)
}

#iubenda_policy .policyicon_purpose_9 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACC0lEQVQ4y7XSO2gWVhQH8BPxDRG%2BEhurMcSI4GsoPqjkZ6BLwcFFHUQJKqbEwRciDqZDF90cpIsILtZHh0KTIdQMgkTRiIshBoWgTRpbsVaxgqRf4uM4JCHfRzpIwXun8%2Bf%2BuHDOifj%2FxwoD2qek7Qat%2FG9Qr1%2FblLRNv%2FqyqKHCjIgIqw3oGE9mmtlQERGhw4DVERFmNFREhG91uq6gxUspnVdlky5dNqlyXkovtSi4rtPe8JeUaq1yWLN9tkVoklJThK1a7HXISrVSehpSGrXb5woWqFZljZNSOmmtBRapUe0Lu4xKOQZSr0633dejS7chKQ25p0%2BvHn3u6Bt7OQFSeuWG3pI6DbvpZ5dc8WwimwTPbYswx49Sei89sDNCpaoI6%2FyqWA5OmxUR4StF6Z0hX5puvyH%2FOmeeudrLwXfjg1prUCo6FuGyty444W89CpYZKQU%2FmF3ywwvVthtxwpwImz1yzjSdpWBYq2nWuzbWoQgX%2FaPOAd%2Br1O55hDOl4LHdDRXqnPVWehLhlPSNgiURFlof4adJMGC7eRERarRKr32t2qBn9lhlg%2BVq7fDbJDhasp%2BfueW9brOscdULv7vntlselnZpadlKH5fSRYvN16ytdJgT4KBGGzVqtNFmv4yndzWrt8WjqSCNGFZUNOxN2Xq8K6%2FD47Et%2FKg7ajAc9edHgz8ciU9%2BPgBKt4%2FTzlslzAAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .policyicon_purpose_10,#iubenda_policy .policyicon_purpose_15 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .policyicon_purpose_13 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAJ1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9PhkGkAAAADHRSTlMAECBAUHCQoLDA4PB7ua%2BoAAAAa0lEQVR42p3QQQ6AIAxE0aEIFdr7n1eMxIAOMfEt%2B9sF4IOkYt5YSTKO1Qd6p%2BQP6Zqrvyjd7zdiLJggO5VReajwhR%2FBnDIoDwrhQcAfkhd%2FtQO0KDqf1A0kmEZgDjk2AZPzPoJo6wFEYOsHFFISOn%2BKxfoAAAAASUVORK5CYII%3D)
}

#iubenda_policy .policyicon_purpose_14 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwUGBwgJCgsMDQ4PASl6hyAAAAfklEQVR42pXRUQ6EMAgE0MEWW21l7n9btanJWnE3%2Bz4hhCHgq5jKooKD6FJS7OVQebIIROOphlY3dqrsLABidJgg0ZWw0bWBL%2F5vvO%2FIdGVM%2Fh0TMNMx47DwYcVJKgdV0MgwUwSXfA%2F0QY2dKW7CxutHA1lbHMFTavE9qsBvOztlFTRVyS%2BYAAAAAElFTkSuQmCC)
}

#iubenda_policy .policyicon_purpose_16 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAJFJREFUeF6V0NsOAyEIRVE6I4rFwvn%2Fr63N3CR10nQnPK2IUdpbpKmsorJQqOKTl2xeRhDsycMgA7QDGkmfq9cI%2FvNEhGcAO8CowAbAGTEwX1XDKvYNnJM7f78clVqfydOlgwRIG6S1TwDdQEnD3cv1iWw4f54VQ1qfUO5QDDGYVLNCmOQ5O2Ea8R2kP8FWobvefhoT%2FSVCMbAAAAAASUVORK5CYII%3D)
}

#iubenda_policy .icon_ribbon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR42u3OwQkAIQxE0XSWVrazlJpdQdGDC0pQEf7A3ELmibsPV1V9pDM%2FAQAAAAAAAAAAAAAAEAXY1%2BcUwCQnITYD6niL2ASo4z3EaoDKf8qNBQHxArgK8ALKMXCw%2Bim7vwAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .icon_owner {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwQGBwgJCgsMDQ4PC8YWy5AAAAiElEQVR42o2QwRKFIAhFIcwyRP7%2Fb3uNlkBv0dkw3jODd4AbPHhNC7xAafqjYBRZOzUa0cHmc9IbiZsefIFtiuQ68RS7FUkNnwTWmRewLE9ewSPh73dfCgJbzxkiRxcrDGJhWVxa5MqYr1HzcLSPRo2ojcoZAcyV2F1MzaPoxIqcP4gGkP5BcAIxQBCQ7o5t3AAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .icon_general {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC)
}

#iubenda_policy .icon_temple_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABwklEQVR42s3Wu0vDUBjGYS%2BLsbZSSaWgguAFHFztYEmFbp0E%2FwOrgotQFyetOKiTLqKCWCenbl20S8FFERydBLt1KV7QwUGR4094hQymjYpi4SGH9zvf%2BUgCoQ3GmF%2F1dwNq%2FRzHaUwkEn24lP73rEaL%2FwEcZmEcJexiDyfKrG8P0OG9OIDBrCvPKMuh98sDaApiAmWYj8fiqg%2FjSrWy9gbrDlDzEHIwLi9YRieiWMOrakIPvZ4DKHYhjTsYD%2Be48Kqrdwpdnw1I4RAFbCKHHWxhX%2BtjHGFb2ZbynHoKOiNV7x3YrnWLrmFFWqvm6vH7DmK4ho0l5NGGCialoiyvPbZ6Yn4GOHhCFBsoIQSDOTHKStoTVY%2FjZ0Act7CxiiICqGJaqsqK2mOrJ%2F6VARGs6ZA2ZTNyq6yoPZF%2FNWC0xiOaEq9HNOpnQBIG3djFGcLKFsQoO8UOepQl%2FQyIIKtP9BjSylcwghhWlKWR0N4sIp4D1NCKDgR0DSIEGxbCYikLibvH8voWNWMdD6jiEXe4waOye1GdGntc9Qcso8nrDtoxgEFdIcp81INed7CIBWQw%2F00ZnbH42YAXef4RnfNn%2FyreALybXwSLU3v7AAAAAElFTkSuQmCC)
}

#iubenda_policy .icon_box_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABv0lEQVR42t3Vz0uTcRzAcUMyWhmWdAgqkKDMiBHt0A%2FYsploddwpO1gk0l%2BQEEQU0iUPgd0LIpCOCh2KkG4GTaI6mqAmWhG0CQ5jfXsdntN4bJPRxS%2B84IFnz%2Ff97Nl4Pg0hhP9qkwT%2BtTKZTCN7uM0qeTrZuc4ltQVs0EqK%2B3xmigc8Z5E39HOI7TUFfHAbJxngNQtM0kdzg5VOp7c4TvKYeeZ4wmXaYgNONHGcW7zjA6Mkqzy%2BZq4zzgxj5DhcGUgxwzSZ9TasEtvFCL%2F4WBm4SKDAPXrZV%2BPGWznFVSYIhMpAliILhEieO5ynMWbjDgZ4xjxlAn%2FiAt0skeMG44TIT8boo51zDPOeEqFSXKAnCrRHd5fgNMN8IrDCFLOUCVB74CsdZBlkL03s5xG%2FCcAGA73M0cIViswyQisn%2BFZPoIdljkWP6AIvKDFIiuV6Al2s8JIciSh0hqNk%2BVHvNwiRNd5ylwNRqKvewNnYv1z0O%2FCQ7xsIFCsDCS4xyhKhwiprhCqmGaIz%2Fm0qxBGu8YoCoYpFntLNwbS3ba3zYDdJhshTohwpMEE%2FbeyoMg%2BqTrQWbvKFSSxDJn5tsqH%2FF0QW2NxzTlYFAAAAAElFTkSuQmCC)
}

#iubenda_policy .icon_tools_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABdklEQVR42rXVzyuDcRzA8WU3ctOag0RiO5mEg8MKcaJc%2FAlcdxppKPlZdpeTE0dqxZWaaEnh7DShlYuJbG0f78Nz%2BPj2zfM89Wz1qmff5%2FPdu%2B27tpCINNTfJzySyeQGhkPGg7UljBtrEbxDMOkaYCiKJ8QtgR0cGWt9EMe8l8AIBAOWwCGKxtqMCux5CSRQxyW61QtNo4yCEVhWgRzCboFm5CF4xTVuUYUgZQTOVeAZHV4OeRSfEMMFWoxAVt2%2FQtQ14Gzsx43anEWr5Vx61Eza9Qz%2B%2BXw71S09M6hm1v0GVtXmWCMCa2pz3BpgXc1kAg2w1oW0mjnGRJCBOYjhLshAO96MQCroM3hQMzUMBfkOZvEDUU78BFbUxl5LYBdiqPgJbKmNCUtg2xL48PpTMYVvFFDEPdqCDDwi51xHIVi0%2FAGJoew18IJ957oJFWx6CHx5DSygjgPkUULM8i2qGoFTP4ecgaCEMcshh5FFDYIzRGyBhvoF3n%2ByMxzF1ykAAAAASUVORK5CYII%3D)
}

#iubenda_policy .icon_paper_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABYklEQVR42t3VP0vDQBjH8Tq4SKXiWFwcRHARVyspgq9AOjkoLoKTcymk%2BjKKYDcnO6iLKPgHRJ11aVGkgt2M2IiWlobzKzyB4zBwBGvBwIdc7rnjR8ITLqGU6qm%2FC8hms%2Bo39TWgg0fUUJX7PV7RlVo1Qtsm4Ckhl%2BM4A%2BGY%2BQJ8TCYiLmp1m4AHWbyGa%2BxiFEtQMneIY80RTtCyCahJwDpOsYc0FtHGDS5wbjjDp03AM6aRRhIpTGAbH5jBEJIm208UwMcbmuIFHam34KEZ0tYGNgEeXFEUW9jAKvLYRFHjCs8moIEMZpHRxnNwzJrx3Oj%2Fj2a%2BQbT4b%2BDBjcmz6iK9M6LF76I6UnHY%2Fgc%2BythB2YK%2B1tcC%2Ful54COPMeT0tsMBxsW%2B0dY52VPAe1RAgAoGw7OA8QoUbvVzgPEU7qS2HJ4b33tRQfBTQBcl4wBZgMIlhrX5EVxJbd7YU0JXD%2BipLw8sG8DBrCLzAAAAAElFTkSuQmCC)
}

#iubenda_policy .icon_man_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABLUlEQVR42t3VsUrDUBSAYaWtSwSp6NAH6CbOVSHZ9QEEBx9AEPc6OAh9Bp0KDg6igqs4tktXFQQnKQiKiNRAp%2BT4C2cImqbXc3Fp4VsazvlpuUmmRORfTUig6BNFURnbeISoB2yhlDPiHvhegF18Qn74wA5KPoE13EFGuEXDFGBwBoeQMQ5QsQSqOHUInGDOEljElUPgEguWwDzOHQJnqFoCszh2CBwhsAQq2EdSsDxFE2XrMV3HS0HgFRs%2B90ENNwWBa9TMgTAMp%2FWR8J6z%2FA2bXo8K%2FRXLuB9xFy%2BZAwwHWMUF4pxArNdWnE%2BRLm2ghS76SMecoj66aOls8CvAl3XsoYNnDCF%2FNNTZju6qZwO93L%2FBLkYvGxggVeIpVYNsoI0nJBBPie5qT8ZL%2FwsLLryq%2FnnTDAAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .icon_keyhole_24 {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABIUlEQVR42rXUP0vCQRjA8bIoiAppLmwQEmehSX5RLTb2Huw9RGODLvUCXG0Kp16ALhENNRjRXLRlIeafJOyevkNu14M89%2FPgMx133%2BF4bkZEpkrdHK8oipIoowuHK2QxGxzgkk1cYgD3R9BEAQlzgMOruMA3xOMZuZDAEToQxRkWrYEHOIiiibQ1IBMEOtixBn4mCLSRtwbqIKIGbrFhDRzgA6I4xpw1kMA5ep6LHa6RCh20ddx4Aq%2FYj2OSF1D3BJ6QjCOQwosn8IbtOAK7%2BPQEvlAcf3ghj1zE4J9HrmApJDCPkvLZ3WMtJLCCmjLNLWRCAmk8KkPWxyHMgQLaSmCIk5BJPkVfCYxQxbI10MBQCTjcYcsaeMcIomhhTwtM1S9g12NLa1YPRQAAAABJRU5ErkJggg%3D%3D)
}

#iubenda_policy .iub_base_container {
    border-radius: 3px;
    background: #fff;
    color: #595858;
    position: relative
}

#iubenda_policy .iub_base_container>.close {
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAw1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4eHh1dXUAAAAAAAAAAAAAAAAAAABfX18AAAAAAAAAAAAAAAAAAAAAAAA2NjYAAAAAAAArKyvJycne3t7X19eFhYWxsbGVlZWsrKzr6%2BvLy8vJycnv7%2B%2Fp6enS0tLi4uL09PTv7%2B%2F8%2FPz7%2B%2Fv4%2BPj39%2FcAAABPT09fX19vb2%2F%2F%2F%2F9S%2BfXQAAAAPHRSTlMAAgMEBQYHCgsMDQ4PEhMWGRobHB8gIiMkJScoKSs0NT1DRUpMWF5gjpOYmaGjpr%2FIys3S1dnZ7vP09vfFQC13AAAA9ElEQVR42oXQZ6%2BCMBiG4aeCAoqiuPceuPes8P7%2FX6Xn5RgBTbw%2BNO3dpG2KH0RcM5JJQ4uLUE2UnSM9HZ1y4r0TM50z%2FTs7ZuyVSysKWJX8DZHeUsg2zUfpU4qY6gBE8xLtl6YAtAnP79Ij8uSdFxMNsHY8lVK67nPgxc4CisQ8yTxiRaBGPvcvu%2BSrAY1vvQHUv51TByqHz3sPFcCef75zbgOpwZUiroMUoFSX0b6sKgDMzjqc1x2Tvznb2wTzppf1P1q1u7PTq55mXVuFT7Va48X%2BRnTbL8YtizMTRqHdH45Gw367YAgEKHoml8%2FnMroC9gCKfVabzD1q%2BwAAAABJRU5ErkJggg%3D%3D) no-repeat;
    border: none;
    display: block;
    position: absolute;
    z-index: 110;
    top: -10px;
    right: -9px;
    height: 24px;
    width: 23px
}

#iubenda_policy p {
    line-height: 19px;
    margin: 0;
    padding-top: 11px
}

#iubenda_policy address {
    margin: 0;
    line-height: inherit;
    display: inline
}

#iubenda_policy a {
    font-weight: normal;
    border-bottom: 1px solid #F0F0F0
}

#iubenda_policy .iub_content {
    position: relative;
    padding: 25px 30px;
    margin: 0 auto;
    border-radius: 3px 3px 0 0
}

#iubenda_policy #wbars {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden
}

#iubenda_policy #wbars .horizontal {
    display: none
}

#iubenda_policy .iub_header {
    border-bottom: 1px dotted #DFDFDF;
    padding-bottom: 25px;
    position: relative
}

#iubenda_policy .iub_header p {
    margin: 0;
    padding: 0
}

#iubenda_policy .iub_header img {
    display: block;
    position: absolute;
    top: 5px;
    right: 0
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3 {
    color: #262626;
    margin: 0
}

#iubenda_policy h1+p,#iubenda_policy h2+p,#iubenda_policy h3+p {
    padding-top: 5px
}

#iubenda_policy h1 {
    font-size: 19px;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 5px
}

#iubenda_policy h2 {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px
}

#iubenda_policy h3 {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    padding-top: 24px
}

#iubenda_policy h3+p {
    padding-top: 0
}

#iubenda_policy .iconed ul li h3 {
    padding-top: 10px;
    color: #615e5e
}

#iubenda_policy h4 {
    font-size: 13px;
    font-weight: bold;
    padding-top: 19px;
    margin-bottom: 0
}

#iubenda_policy h4:first-child {
    padding-top: 0
}

#iubenda_policy h6 {
    color: #262626;
    text-transform: none;
    padding-top: 19px
}

#iubenda_policy ul.for_boxes,#iubenda_policy ul.for_boxes>li,#iubenda_policy ul.unstyled,#iubenda_policy ul.unstyled>li {
    list-style: none;
    padding: 0;
    margin: 0
}

#iubenda_policy ul.for_boxes {
    zoom:1}

#iubenda_policy ul.for_boxes:before,#iubenda_policy ul.for_boxes:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy ul.for_boxes:after {
    clear: both
}

#iubenda_policy .half_col {
    float: left;
    width: 50%;
    zoom:1}

#iubenda_policy .half_col:before,#iubenda_policy .half_col:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .half_col:after {
    clear: both
}

#iubenda_policy .half_col:nth-child(2n+1)>* {
    margin-right: 15px
}

#iubenda_policy .half_col:nth-child(2n)>* {
    margin-left: 15px
}

#iubenda_policy .half_col+.one_line_col,#iubenda_policy .half_col+.iub_footer {
    border-top: 1px dotted #DFDFDF
}

#iubenda_policy .one_line_col {
    zoom:1;float: left;
    width: 100%;
    border-bottom: 1px dotted #DFDFDF
}

#iubenda_policy .one_line_col:before,#iubenda_policy .one_line_col:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .one_line_col:after {
    clear: both
}

#iubenda_policy .one_line_col>ul.for_boxes>li {
    float: left;
    width: 50%
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1) {
    clear: left
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 15px
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n) {
    clear: right
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 15px
}

#iubenda_policy .one_line_col.wide {
    width: 100%
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy ul.normal_list {
    list-style: disc;
    display: block;
    padding-top: 11px
}

#iubenda_policy ul.normal_list li {
    list-style: disc;
    float: none;
    line-height: 19px;
    margin: 5px 25px
}

#iubenda_policy .simple_pp>ul>li {
    padding-bottom: 21px
}

#iubenda_policy .simple_pp>ul>li>ul .iconed {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 2px;
    background-position-y: 26px;
    background-position: 2px 26px
}

#iubenda_policy .simple_pp .for_boxes>.one_line_col>ul.for_boxes {
    margin-top: 0
}

#iubenda_policy .legal_pp .one_line_col {
    float: none;
    border-top: 0;
    padding-bottom: 21px
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes {
    margin-top: 21px
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n+1) {
    clear: left;
    float: left
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n) {
    float: right;
    clear: right
}

#iubenda_policy .legal_pp .definitions {
    margin-top: 21px
}

#iubenda_policy .legal_pp .definitions .expand-click.w_icon_24 {
    margin-top: -11px;
    padding: 14px 10px 12px 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 5px;
    background-position-y: 0;
    background-position: 5px 0
}

#iubenda_policy .legal_pp .definitions .expand-content {
    padding-left: 5px;
    padding-right: 5px
}

#iubenda_policy .wrap p {
    display: inline-block
}

#iubenda_policy .iub_footer {
    clear: both;
    position: relative;
    font-size: 11px
}

#iubenda_policy .iub_footer p {
    font-size: 11px;
    padding: 0
}

#iubenda_policy .iub_content .iub_footer {
    padding: 24px 0
}

#iubenda_policy .iub_content .iub_footer p {
    margin: 10px 0;
    clear: both
}

#iubenda_policy .iub_content .iub_footer .show_comp_link {
    display: block;
    float: right
}

#iubenda_policy .iub_container>.iub_footer {
    min-height: 21px;
    background-color: #F6F6F6;
    color: #717171;
    padding: 30px;
    -webkit-box-shadow: 0 -1px 6px #CFCFCF;
    box-shadow: 0 -1px 6px #CFCFCF;
    border-radius: 0 0 3px 3px
}

#iubenda_policy .iub_container>.iub_footer>.btn {
    position: absolute;
    top: 25px;
    right: 30px
}

#iubenda_policy .iub_container>.iub_footer .btn {
    padding: 0px 24px;
    line-height: 29px
}

#iubenda_policy .iub_container>.iub_footer .button-stack {
    margin: -4px 0
}

#iubenda_policy .iub_container>.iub_footer .button-stack .btn+.btn {
    margin-left: 5px
}

#iubenda_policy .iub_container>.iub_footer img {
    margin: -4px 3px 0;
    vertical-align: middle;
    width: 70px;
    height: 25px
}

#iubenda_policy .wide {
    width: 150px
}

@media (max-width: 767px) {
    #iubenda_policy .legal_pp .one_line_col,#iubenda_policy .legal_pp .half_col {
        width:100%
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li {
        clear: both;
        width: 100%
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
        margin-right: 0
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li:nth-child(2n)>div {
        margin-left: 0
    }

    #iubenda_policy .iub_header img {
        position: static;
        margin-bottom: 12.5px
    }

    #iubenda_policy .iub_content>.iub_footer .show_comp_link {
        position: static;
        display: inline
    }

    #iubenda_policy .iub_container>.iub_footer {
        padding: 20px
    }

    #iubenda_policy .iub_container>.iub_footer .btn {
        top: 15px;
        right: 15px
    }

    #iubenda_policy .iub_base_container>.close {
        content: "X";
        color: #000;
        font-size: 11px;
        line-height: 18px;
        padding: 0;
        text-align: center;
        border-radius: 30px;
        display: block;
        background: #fff url(/../design/images/close_big.png) no-repeat;
        background-position-x: 18px;
        background-position-y: 18px;
        background-position: 18px 18px;
        position: absolute;
        z-index: 110;
        top: -10px;
        right: -10px;
        margin: 5px 5px 0 0;
        height: 57px;
        width: 60px;
        -webkit-box-shadow: 0 1px 1px #000000;
        box-shadow: 0 1px 1px #000000
    }

    #iubenda_policy .iub_base_container>.close.small {
        width: 14px;
        line-height: 14px;
        border-radius: 9px;
        padding: 0
    }
}

@media (max-width: 480px) {
    html#iubenda_policy {
        padding:0
    }

    #iubenda_policy body {
        padding: 0
    }

    #iubenda_policy .iub_base_container,#iubenda_policy .iub_container {
        margin: 0
    }

    #iubenda_policy .half_col:nth-child(2n+1)>* {
        margin-right: 0
    }

    #iubenda_policy .half_col:nth-child(2n)>* {
        margin-left: 0
    }

    #iubenda_policy .one_line_col,#iubenda_policy .half_col {
        width: 100%
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li,#iubenda_policy .half_col>ul.for_boxes>li {
        clear: both;
        width: 100%
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
        margin-right: 0
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
        margin-left: 0
    }

    #iubenda_policy .iub_header img {
        position: static;
        margin-bottom: 12.5px
    }

    #iubenda_policy .iub_content>.iub_footer .show_comp_link {
        position: static;
        display: inline
    }

    #iubenda_policy .iub_container>.iub_footer {
        padding: 10px;
        text-align: center
    }

    #iubenda_policy .iub_container>.iub_footer .btn {
        position: static;
        width: auto;
        display: block;
        margin: 10px auto 0 auto;
        max-width: 200px
    }

    #iubenda_policy .iub_container>.iub_footer.in_preview {
        padding: 30px 10px
    }

    #iubenda_policy .iub_content {
        padding-left: 20px;
        padding-right: 20px
    }

    #iubenda_policy .iub_base_container>.close {
        content: "X";
        color: #000;
        font-size: 11px;
        line-height: 18px;
        padding: 0;
        text-align: center;
        border-radius: 30px;
        display: block;
        background: #fff url(/../design/images/close_big.png) no-repeat;
        background-position-x: 18px;
        background-position-y: 18px;
        background-position: 18px 18px;
        position: absolute;
        z-index: 110;
        top: -10px;
        right: -10px;
        margin: 5px 5px 0 0;
        height: 57px;
        width: 60px;
        -webkit-box-shadow: 0 1px 1px #000000;
        box-shadow: 0 1px 1px #000000
    }

    #iubenda_policy .iub_base_container>.close.small {
        width: 14px;
        line-height: 14px;
        border-radius: 9px;
        padding: 0
    }
}

#iubenda_policy.iubenda_fixed_policy .iub_base_container {
    max-width: 800px
}

#iubenda_policy.iubenda_fixed_policy .iub_container {
    margin-left: auto;
    margin-right: auto;
    zoom:1}

#iubenda_policy.iubenda_fixed_policy .iub_container:before,#iubenda_policy.iubenda_fixed_policy .iub_container:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy.iubenda_fixed_policy .iub_container:after {
    clear: both
}

#iubenda_policy.iubenda_fluid_policy #wbars {
    overflow-y: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto
}

#iubenda_policy.iubenda_fluid_policy .iub_container {
    margin-top: 30px;
    margin-bottom: 30px
}

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n+1)>* {
    margin-right: 0
}

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n)>* {
    margin-left: 0
}

#iubenda_policy.iubenda_fluid_policy .one_line_col,#iubenda_policy.iubenda_fluid_policy .half_col {
    width: 100%
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy.iubenda_embed_policy .iub_base_container {
    background: none
}

#iubenda_policy.iubenda_embed_policy .iub_container>.iub_footer {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none
}

#iubenda_policy.iubenda_embed_policy .expand-click {
    cursor: default
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .iub_base_container {
    color: #666
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h2 {
    font-size: 24px;
    padding-top: 50px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h3 {
    color: #444;
    font-size: 20px;
    padding-top: 45px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h4 {
    font-size: 16px;
    padding-top: 40px;
    color: #555
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h5 {
    font-size: 14px;
    padding-top: 35px;
    margin-bottom: 0;
    color: #666
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h6 {
    font-size: 12px;
    color: #505050;
    text-transform: uppercase;
    padding-top: 32px;
    margin-bottom: 0
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions {
    margin-top: 60px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content {
    padding: 25px 15px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content h4 {
    font-size: 15px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions:before {
    content: "";
    border-top: 1px dotted rgba(0,0,0,0.1);
    display: block;
    margin: 0 -10px;
    position: relative;
    top: -45px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions h2 {
    font-size: 13px;
    color: #333
}

#iubenda_policy.iubenda_vip_policy.iubenda_fixed_policy .iub_container {
    max-width: 660px;
    padding-top: 80px
}

#iubenda_policy.iubenda_vip_policy .iub_base_container {
    color: #595858
}

#iubenda_policy.iubenda_vip_policy p {
    font-size: 14px;
    line-height: 1.6
}

#iubenda_policy.iubenda_vip_policy .allcaps,#iubenda_policy.iubenda_vip_policy p.allcaps,#iubenda_policy.iubenda_vip_policy ul.allcaps li {
    font-variant: small-caps !important;
    font-weight: bold !important;
    font-size: 16px !important;
    font-family: Arial!important
}

#iubenda_policy.iubenda_vip_policy ul li {
    font-size: 14px;
    line-height: 1.6
}

#iubenda_policy.iubenda_vip_policy h1 {
    font-size: 30px;
    color: #141414;
    line-height: 1.6;
    margin-bottom: 60px
}

#iubenda_policy.iubenda_vip_policy h2 {
    font-size: 18px;
    color: #141414;
    line-height: 1.6;
    padding-top: 50px;
    padding-bottom: 15px
}

#iubenda_policy.iubenda_vip_policy h3 {
    color: #141414;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
    padding-bottom: 50px
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n+1)>* {
    margin-right: 0
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n)>* {
    margin-left: 0
}

#iubenda_policy.iubenda_vip_policy .one_line_col,#iubenda_policy.iubenda_vip_policy .half_col {
    width: 100%
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy.iubenda_vip_policy .definitions,#iubenda_policy.iubenda_vip_policy .iub_footer,#iubenda_policy.iubenda_vip_policy .for_boxes {
    color: #505762
}

#iubenda_policy.iubenda_vip_policy .definitions h3,#iubenda_policy.iubenda_vip_policy .definitions p,#iubenda_policy.iubenda_vip_policy .definitions li,#iubenda_policy.iubenda_vip_policy .iub_footer h3,#iubenda_policy.iubenda_vip_policy .iub_footer p,#iubenda_policy.iubenda_vip_policy .iub_footer li,#iubenda_policy.iubenda_vip_policy .for_boxes h3,#iubenda_policy.iubenda_vip_policy .for_boxes p,#iubenda_policy.iubenda_vip_policy .for_boxes li {
    font-size: 13px
}

#iubenda_policy.iubenda_vip_policy .w_icon_24 {
    background-image: none
}

#iubenda_policy.iubenda_vip_policy .box_10.expand .expand-click.w_icon_24 {
    padding-left: 10px
}

#iubenda_policy.iubenda_vip_policy .box_primary {
    border-color: #E0E0E0;
    border-bottom-color: #D3D3D3;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy.iubenda_vip_policy .box_primary h3 {
    color: #333
}

#iubenda_policy.iubenda_vip_policy .tc-deactivated h1 {
    font-size: 20px;
    margin-bottom: 10px
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
    padding-bottom: 21px
}

</style>